/** 入力エラーメッセージオブジェクト */
export class GenerateInputErrorMessage {
  // カラムID
  private _columnId: string;

  // カラム名称
  private _columnName: string;

  // カラムエラーメッセージ
  private _columnErrorMessage: string;

  set columnId(columnId: string) {
    this._columnId = columnId;
  }

  get columnId(): string {
    return this._columnId;
  }

  set columnName(columnName: string) {
    this._columnName = columnName;
  }

  get columnName(): string {
    return this._columnName;
  }

  set columnErrorMessage(columnErrorMessage: string) {
    this._columnErrorMessage = columnErrorMessage;
  }

  get columnErrorMessage(): string {
    return this._columnErrorMessage;
  }
}
