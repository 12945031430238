<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 入力エラーメッセージ出力領域 -->
  <p-messages severity="error" *ngIf="inputErrorMessageList.length">
    <!-- 入力エラーメッセージが存在する場合、表示 -->
    <ng-template pTemplate>
      <div class="error-message-box p-d-flex p-jc-between">
        <div class="p-message-detail p-d-flex p-flex-column">
          <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
          <div *ngFor="let inputErrorMessage of inputErrorMessageList">
            販促資材コード：
            <!-- 販促資材コード -->
            {{ inputErrorMessage.itemId }}
            <br />

            <!-- エラーメッセージリスト分ループ-->
            <div
              *ngFor="let errorMessage of inputErrorMessage.errorMessageList"
            >
              <!-- カラム名称 -->
              {{ errorMessage.columnName }}：
              <!-- エラーメッセージ -->
              {{ errorMessage.message }}
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>
    </ng-template>
  </p-messages>

  <!-- 一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped stock-datatable"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
          <!-- 登録-->
          <button
            type="button"
            pButton
            label="登録"
            (click)="checkRegistr()"
            class="p-mr-2 registrationButton"
          ></button>
          <!-- 一括入力 -->
          <button
            type="button"
            pButton
            label="一括入力"
            class="p-mr-2 bulkInputButton"
            (click)="onClickBulkInput()"
          ></button>
        </div>
        <div>
          <!-- 全件csv出力-->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header" [formGroup]="bulkInputForm">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <!-- カラム判定 -->
          <!-- 画像以外の場合 -->
          <th
            [pSortableColumn]="column.field"
            *ngIf="column.input_type != 'IMAGE'"
          >
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
          <!-- 画像の場合 -->
          <th *ngIf="column.input_type == 'IMAGE'">
            {{ column.header }}
          </th>
        </ng-container>
        <!-- API入力項目ヘッダーの表示 -->
        <ng-container *ngFor="let inputColumn of inputColumnOrder">
          <th>
            {{ inputColumn.column_name }} <br />

            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->
            <input
              [name]="inputColumn.column_id"
              type="text"
              [maxlength]="inputColumn.column_hsize"
              [formControlName]="inputColumn.column_id"
              *ngIf="inputColumn.input_type == 'text'"
              pInputText
            />
            <!-- 日付 -->
            <p-calendar
              [name]="inputColumn.column_id"
              [showIcon]="true"
              [showButtonBar]="true"
              [yearNavigator]="true"
              [monthNavigator]="true"
              dateFormat="yy/mm/dd"
              dataType="string"
              appendTo="body"
              yearRange="2000:2999"
              [formControlName]="inputColumn.column_id"
              *ngIf="inputColumn.input_type == 'date'"
              translate
            >
            </p-calendar>
            <!-- 数字 -->
            <input
              [name]="inputColumn.column_id"
              type="number"
              [formControlName]="inputColumn.column_id"
              *ngIf="inputColumn.input_type == 'number'"
              pInputText
            />
          <!-- シングルセレクト -->
          <p-dropdown
            [name]="inputColumn.column_id"
            [options]="inputColumn.column_code_list_multi"
            placeholder="　"
            optionLabel="name"
            [showClear]="true"
            [filter]="true"
            emptyFilterMessage="検索結果が存在しません"
            appendTo="body"
            [formControl]="bulkInputForm.controls[inputColumn.column_id]"
            *ngIf="inputColumn.input_type == 'single_select'"
          >
          </p-dropdown>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-searchResults [formGroup]="inputForm">
      <!-- 子フォームグループ -->
      <tr [formGroupName]="searchResults[getPkeyColumn()]">
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[getPkeyColumn()])"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                <!-- 数値の場合 -->
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchCase="'IMAGE'">
                <!-- 画像の場合 -->
                <img [src]="searchResults[column.field]" alt="image" />
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>

        <!-- 入力項目表示 -->
        <ng-container *ngFor="let inputItem of inputItemList">
          <!-- 入力項目タイプを判定 -->
          <!-- テキスト -->
          <td *ngIf="inputItem.input_type == 'text'">
            <input
              [name]="inputItem.column_id"
              type="text"
              [maxlength]="inputItem.column_hsize"
              [class.errorItem]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
              "
              [formControlName]="inputItem.column_id"
              pInputText
            />
          </td>
          <!-- 数字 -->
          <td *ngIf="inputItem.input_type == 'number'">
            <input
              [name]="inputItem.column_id"
              type="number"
              [class.errorItem]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
              "
              [formControlName]="inputItem.column_id"
              pInputText
            />
          </td>
          <!-- 日付 -->
          <td *ngIf="inputItem.input_type == 'date'">
            <p-calendar
              [name]="inputItem.column_id"
              [showIcon]="true"
              [showButtonBar]="true"
              [yearNavigator]="true"
              [monthNavigator]="true"
              dateFormat="yy/mm/dd"
              dataType="string"
              appendTo="body"
              yearRange="2000:2999"
              [inputStyleClass]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
                  ? 'errorItem'
                  : ''
              "
              [formControlName]="inputItem.column_id"
              translate
            >
            </p-calendar>
          </td>
          <!-- シングルセレクト -->
          <td
            class="inputDropdown"
            *ngIf="inputItem.input_type == 'single_select'"
          >
            <p-dropdown
              [name]="inputItem.column_id"
              [options]="inputItem.column_code_list_multi"
              placeholder="　"
              optionLabel="name"
              [showClear]="true"
              [filter]="true"
              emptyFilterMessage="検索結果が存在しません"
              appendTo="body"
              [styleClass]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
                  ? 'errorItem'
                  : ''
              "
              [formControl]="
                inputForm.controls[searchResults[getPkeyColumn()]].controls[
                  inputItem.column_id
                ]
              "
            >
            </p-dropdown>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(table, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  class="dialog"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
