import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  API_URL_EVENT,
  API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS,
  API_URL_INPUT_EVENT,
  API_URL_SEARCH_EVENT,
} from 'manager/http-constants_key';
import { PROJECT_LIST_TEMPLATE } from 'manager/template-constant';
import { ConfirmationService } from 'primeng/api';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import {
  USER$ADMIT,
  DEPARTMENT$DEPARTMENT_TYPE,
} from 'src/app/shared/constant/db-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PROJECT_LIST_CONSTANT, STOCK_FILE_DATA } from './constant';
import { ProjectManagementDetailComponent } from './project-management-detail/project-management-detail.component';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['../project.component.scss', './project-list.component.scss'],
})

/**
 * 計画一覧画面
 */
export class ProjectListComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 詳細画面(管理・承認ユーザ) */
  @ViewChild(ProjectManagementDetailComponent)
  projectManagementDetailComponent: ProjectManagementDetailComponent;

  /** 新規登録画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_EVENT;

  // 検索項目生成テンプレートID
  searchTemplateId: number =
    // ログインユーザを判定
    DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type &&
    USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
      ? // ログインユーザが一般の場合
        PROJECT_LIST_TEMPLATE.GENERAL_SEARCH_TEMPLATE_ID
      : // ログインユーザが管理・承認ユーザの場合
        PROJECT_LIST_TEMPLATE.MANAGEMENT_SEARCH_TEMPLATE_ID;

  // 検索項目生成条件
  searchOueryParameters: object = { category1: 2 };

  // 入力項目生成条件
  inputOueryParameters: object = { category1: 2 };

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 辞書値格納先
  dicValuesList: any[] = new Array();

  // 製作指示ファイルDL選択リスト格納先
  stockFileSelected: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  // 検索結果合計計画数
  searchSumPlanNum: number = 0;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
          USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            PROJECT_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理・承認ユーザの場合
            PROJECT_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID
      )
      .subscribe((response) => {
        // ヘッダー情報格納
        this.columnOrder = response.body;

        // ヘッダー情報分ループ
        for (const headerInformation of response.body) {
          // ヘッダー情報に使用辞書番号が存在するか否か
          if (!headerInformation.column_dictionary_id) {
            // 使用辞書番号が存在しない場合
            continue;
          }

          /* 辞書情報を取得 */
          {
            // 使用辞書番号で辞書情報を取得する
            this.dbOperationService
              .getDicValues(headerInformation.column_dictionary_id)
              .subscribe((dicvalues) => {
                // 辞書情報を格納
                this.dicValuesList = this.dicValuesList.concat(
                  dicvalues.body[0].dic_value
                );
              });
          }
        }
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 製作指示ファイルDL選択リストを初期化
    this.stockFileSelected = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    // 検索条件に無条件でカテゴリを追加
    // TODO 森永案件で使用
    {
      // 初期表示か否か
      if (!generateSearchItems) {
        // 初期表示の場合

        // 検索条件オブジェクトを生成
        generateSearchItems = new Object();
      }

      // 検索条件にカテゴリ1を追加
      generateSearchItems['category1'] = 2;
    }

    /* 計画一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        PROJECT_LIST_CONSTANT.TARGET_TABLE,
        API_URL_EVENT,
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
          USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            PROJECT_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理・承認ユーザの場合
            PROJECT_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 計画一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;

          // 計画一覧情報の計画数を合算値をセット
          this.searchSumPlanNum = this.commonService.sumNumberArrayObject(
            response.body,
            PROJECT_LIST_CONSTANT.SUM_PLAN_NUM
          );
        } else {
          this.searchResultsList = new Array();

          // 計画数の合算値をセット
          this.searchSumPlanNum = 0;
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.5);
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param rowData 行データ
   */
  protected detail(pkeyId: string, rowData: any) {
    // ログインユーザと計画ステータスの判定
    if (
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // ログインユーザが管理ユーザの場合

      // 詳細画面表示(計画管理者詳細画面(編集・複製・削除ボタンが存在)へ遷移)
      let inputGenerateDisplay: InputGenerateDisplay =
        new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_EVENT;
      inputGenerateDisplay.templateId =
        PROJECT_LIST_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
      this.projectManagementDetailComponent.initial(
        inputGenerateDisplay,
        pkeyId,
        rowData
      );
    } else {
      // ログインユーザが管理ユーザ以外の場合

      // 詳細画面表示(表示項目生成(アイテムアダプター)へ遷移)
      let inputGenerateDisplay: InputGenerateDisplay =
        new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_EVENT;
      inputGenerateDisplay.templateId =
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            PROJECT_LIST_TEMPLATE.GENERAL_OUTPUT_TEMPLATE_ID
          : // ログインユーザが承認ユーザの場合
            PROJECT_LIST_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
      this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
    }
  }

  /**
   * 新規登録画面表示
   */
  protected new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      PROJECT_LIST_TEMPLATE.MANAGEMENT_INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 更新画面表示
   * @param table テーブル状態
   * @param no2 数量計画承認No
   */
  protected update(table: any, no2: string, no: string) {
    // 承認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(
        MESSAGE_CODE.T00011,
        this.commonService.getPkeyColumnName(this.columnOrder),
        no
      ),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: '',
            background_color: '',
            opacity: 0.3,
          })
        );

        // DBアップデート
        this.dbOperationService
          .updateData(API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS, no2, {
            approval: '1',
          })
          .subscribe((response) => {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd();

            // 入力レスポンスメッセージを表示
            if (
              this.messageData.responseToastMessage(
                response,
                this.commonService.msg(MESSAGE_CODE.T00001),
                this.commonService.msg(MESSAGE_CODE.U00002)
              )
            ) {
              // 承認・非承認データ置換を実施
              this.searchReplacementApproval(table, no2);
            }
          });
      },
    });
  }

  /**
   * 計画数確認画面へ遷移
   * @param pkeyId IDコード(クリックされたID)
   */
  protected projectNumberConfirmation(pkeyId: string) {
    // 計画数確認画面へ遷移
    this.router.navigate(['pages/project/project-number-confirmation/1'], {
      queryParams: { no: pkeyId },
    });
  }

  /**
   * 計画数入力画面へ遷移
   * @param pkeyId IDコード(クリックされたID)
   */
  protected projectNumberInput(pkeyId: string) {
    // 計画数入力画面へ遷移
    this.router.navigate(['pages/project/project-number-input'], {
      queryParams: { no: pkeyId },
    });
  }

  /**
   * 再検索処理＆データ置換
   * @param table テーブル状態
   * @param pkeyId コード値
   */
  public searchReplacement(table: any, pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが新規,複製の場合 */
    // 返却されたコード値が存在するか否か
    if (!pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施(テーブル状態初期化,全検索)
      this.searchResult(table);

      return;
    }

    /* データが編集かつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(table, this.generateSearchItems);

      return;
    }

    /* データが編集かつソート未実施の場合 */
    this.dbOperationService
      .getSingleData(
        API_URL_EVENT,
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
          USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            PROJECT_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理・承認ユーザの場合
            PROJECT_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
        this.getPkeyColumn(),
        pkeyId
      )
      .subscribe((response) => {
        // 一覧情報分ループ
        for (const body of response.body) {
          /* 検索結果一覧から該当データを更新 */
          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.commonService.getRowNumberArrayObject(
            this.searchResultsList,
            'no2',
            body.no2
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList[indexTargetData] = body;
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * 承認・非承認データ置換
   * @param table テーブル状態
   * @param no2 コード値
   */
  public searchReplacementApproval(table: any, no2: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが編集かつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(table, this.generateSearchItems);

      return;
    }

    /* 計画一覧取得処理(画面用) */
    this.dbOperationService
      .getSingleData(
        API_URL_EVENT,
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
          USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            PROJECT_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理・承認ユーザの場合
            PROJECT_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
        'no2',
        no2
      )
      .subscribe((response) => {
        /* 検索結果一覧から該当データを更新 */
        // 検索結果一覧格納先から対象のデータの行数を抽出
        const indexTargetData = this.commonService.getRowNumberArrayObject(
          this.searchResultsList,
          'no2',
          no2
        );

        // 検索結果一覧情報のJSONをオブジェクトに置換する
        this.searchResultsList[indexTargetData] = response.body[0];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * データ削除処理
   * @param pkeyId コード値
   */
  public searchDeleteReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // 検索結果一覧から該当データを削除
    this.searchResultsList = this.commonService.deleteArrayObjectValue(
      this.searchResultsList,
      this.getPkeyColumn(),
      pkeyId
    );

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadEnd();
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      PROJECT_LIST_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      PROJECT_LIST_CONSTANT.TARGET_TABLE,
      API_URL_EVENT,
      // ログインユーザを判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // ログインユーザが一般の場合
          PROJECT_LIST_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
        : // ログインユーザが管理・承認ユーザの場合
          PROJECT_LIST_TEMPLATE.MANAGEMENT_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * コード値変換
   * @param spName コード名称
   * @returns コード値
   */
  protected changeCode(spName: string): number {
    // コード値を取得
    const code = this.commonService.getArrayObjectValue(
      this.dicValuesList,
      DICVALUES_API_CONSTANT.NAME,
      DICVALUES_API_CONSTANT.CODE,
      spName
    );

    return code;
  }

  /**
   * 表示有効期限判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示]
   */
  // TODO 森永案件で利用
  protected outputDispValid(rowData: any): boolean {
    // 現在日付
    const nowDate = this.datePipe.transform(
      new Date().setDate(new Date().getDate()),
      DATE_FORMAT.DATE
    );

    // 現在日付が表示有効期限内か否か
    if (
      nowDate >=
        this.datePipe.transform(rowData.disp_valid_from, DATE_FORMAT.DATE) &&
      nowDate <=
        this.datePipe.transform(rowData.disp_valid_to, DATE_FORMAT.DATE)
    ) {
      // 表示有効期限内の場合

      return true;
    }

    return false;
  }

  /**
   * 製作指示ファイルDL
   */
  protected stockFileDownload() {
    // 製作指示ファイルDLが選択されているか否か
    if (!this.stockFileSelected.length) {
      // 製作指示ファイルDLが選択されていない場合

      // メッセージを出力
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.T00006),
          detail: this.commonService.msg(MESSAGE_CODE.T00007),
        })
      );
      return;
    }

    // csvファイル名の設定
    const fileName =
      STOCK_FILE_DATA.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME);

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(PROJECT_LIST_TEMPLATE.CSV_STOCK_MAINTENANCE)
      .subscribe((response) => {
        /* 製作指示ファイルDLデータ部作成 */
        let stockFileData;
        {
          // 製作指示ファイルDL該当情報にワークタイプを追加
          stockFileData = this.commonService.addKeyValueArrayObject(
            this.stockFileSelected,
            STOCK_FILE_DATA.WORK_TYPE,
            STOCK_FILE_DATA.WORK_TYPE_DATA
          );

          // 製作指示ファイルDL該当情報にステータスを追加
          stockFileData = this.commonService.addKeyValueArrayObject(
            stockFileData,
            STOCK_FILE_DATA.STATUS,
            STOCK_FILE_DATA.STATUS_DATA
          );

          // 製作指示ファイルDL該当情報の組織コードのキーを変更
          stockFileData = this.commonService.changeKeyArrayObject(
            stockFileData,
            STOCK_FILE_DATA.DEPARTMENT_CODE,
            STOCK_FILE_DATA.STORE_LOCATION_CODE
          );

          // 製作指示ファイルDL該当情報の計画数のキーを変更
          stockFileData = this.commonService.changeKeyArrayObject(
            stockFileData,
            STOCK_FILE_DATA.SUM_PLAN_NUM,
            STOCK_FILE_DATA.PLAN_STOCK_CHANGE_NUM
          );
        }

        // CSVファイル出力
        this.exportFileService.exportCsv(
          fileName,
          response.body,
          stockFileData,
          true
        );
      });
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
