import { Component, OnInit } from '@angular/core';
import { LoadData, LoadingState } from './loading-state';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})

/**
 * ローディング
 */
export class LoadingComponent implements OnInit {
  // ローディング状態
  loading = this.loadingState;

  // ローディング変動テキスト
  changeText: string;

  // ローディング出力フラグ
  outputLoadingFlag: boolean = false;

  constructor(private loadingState: LoadingState) {}

  ngOnInit(): void {}

  ngDoCheck() {
    // ローディングのチェックを実施
    if (this.loadingState.loadingFlag && !this.outputLoadingFlag) {
      // ローディング開始 かつ
      // ローディング出力が未実行の場合

      // ローディング出力を実施
      this.outputLoading();
    }

    // ローディングのチェックを実施
    if (!this.loadingState.loadingFlag) {
      // ローディング終了の場合

      // ローディング出力未実行状態へ変更
      this.outputLoadingFlag = false;
    }
  }

  /**
   * ローディング出力
   */
  private outputLoading() {
    // ローディング出力実行状態へ変更
    this.outputLoadingFlag = true;

    // ローディングテキスト(変動)
    let changeText: string = this.loading.loadData.changeText;

    // 変動テキストカウント
    let cnt: number = 0;

    // 0.5秒ごとに動作
    let timerId = setInterval(() => {
      // 変動テキストカウントが変動テキストの長さを上回るか否か
      if (changeText.length < cnt) {
        // 変動テキストカウントが上回った場合

        // 変動テキストカウントを初期化
        cnt = 0;
      }

      // 変動テキストカウントに+1をする
      cnt++;

      // 変動テキストから取得した長さを、ローディング変動テキストプロパティに格納
      this.changeText = changeText.substr(0, cnt);

      // ローディングのチェックを実施
      if (!this.loadingState.loadingFlag) {
        // ローディング終了の場合

        // ローディングオブジェクトを初期化
        this.loadingState.loadData = new LoadData();

        // ローディング変動テキストを初期化
        this.changeText = undefined;

        // ローディング出力未実行状態へ変更
        this.outputLoadingFlag = false;

        // ローディング出力を停止
        clearInterval(timerId);
      }
    }, 500);
  }
}
