import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validator-message',
  templateUrl: './validator-message.component.html',
  styleUrls: ['./validator-message.component.scss'],
})

/**
 * 単項目エラーメッセージ
 */
export class ValidatorMessageComponent implements OnInit {
  /** コントロール */
  @Input() control: FormControl;

  constructor() {}

  ngOnInit(): void {}

  public hasError(): boolean {
    if (!this.control) {
      return;
    }
    return this.control.errors && this.control.dirty;
  }

  public mapToErrorMessage(): string {
    return this.control.errors.message;
  }
}
