<!-- ファイル登録ボタン -->
<p-fileUpload
  #fileUpload
  mode="basic"
  uploadIcon="pi pi-upload"
  [chooseLabel]="buttonName"
  [auto]="true"
  customUpload="true"
  (uploadHandler)="csvUploadFile($event, fileUpload)"
  class="p-button-raised p-mr-2"
>
</p-fileUpload>
