import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { USER_AUTHINFOS } from 'manager/http-constants_key';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { map } from 'rxjs/operators';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CommonService } from './common.service';
import {
  MessageData,
  ToastMessageData,
} from '../html-parts/message-common/message-data';
import { TOAST } from '../constant/primeng-constants';

@Injectable({
  providedIn: 'root',
})

/**
 * ログイン処理
 */
export class LoginService {
  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private commonService: CommonService,
    private messageData: MessageData
  ) {}

  /**
   * ログイン処理
   */
  public login(): void {
    this.auth.loginWithRedirect();
  }

  /**
   * ログアウト処理
   * @param messageCode メッセージコード
   */
  public logout(messageCode?: string): void {
    // セッションに保存したログインユーザ情報を削除
    window.sessionStorage.removeItem(SESSION_KEY.loginUserInformation);

    // メッセージコードが存在するか否か
    if (!messageCode) {
      // メッセージコードが存在しない場合

      // メッセージコード(ログアウトしました)を標準セット
      messageCode = MESSAGE_CODE.N90000;
    }

    // セッションにログアウト画面用のメッセージコードを格納
    window.sessionStorage.setItem(SESSION_KEY.loginOutMessageCode, messageCode);

    // Auth0のログアウト 及び ログアウト後にメッセージ画面へ遷移
    this.auth.logout({
      returnTo: window.location.origin + '/pages/message',
    });
  }

  /**
   * ログインユーザ情報取得
   * @returns JSONデータ
   */
  public getLoginUser(): Observable<any> {
    // ログインユーザ情報を返却する
    return this.http
      .get(this.commonService.url(USER_AUTHINFOS), { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ログインメッセージ
   * @param loginUserName ログインユーザ名
   */
  public loginMessage(loginUserName?: string): void {
    // ログインメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80000),
        detail: this.commonService.msg(MESSAGE_CODE.N80001, loginUserName),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  /**
   * ログアウトメッセージ
   */
  public logoutMessage(): void {
    // ログアウトメッセージを出力
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.INFO,
        summary: this.commonService.msg(MESSAGE_CODE.N80002),
        detail: this.commonService.msg(MESSAGE_CODE.N80003),
        position: TOAST.TOP_RIGHT,
        life: 5000,
      })
    );
  }

  /**
   * Auth0ログインユーザ情報取得
   * @returns ログインユーザ情報
   */
  public getAuth0LoginUser(): Observable<any> {
    // Auth0ログインユーザ情報を返却する
    return this.auth.user$.pipe((user) => {
      return user;
    });
  }

  /**
   * Auth0ログイン状態取得
   * @returns true:ログイン中,false:ログアウト中
   * ※ セッション切れの場合は、true:ログイン中となる
   */
  public getAuth0LoginState(): Observable<any> {
    // Auth0ログイン状態を返却する
    return this.auth.isAuthenticated$.pipe((authenticated) => {
      return authenticated;
    });
  }

  /**
   * Auth0ログインユーザトークン
   */
  public getAuth0LoginToken(): Observable<any> {
    // Auth0ログインユーザトークンを返却する
    return this.auth.idTokenClaims$.pipe((tokenClaims) => {
      return tokenClaims;
    });
  }
}
