import { Component, Input, OnInit, Optional, Self, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-generate-radio-input',
  templateUrl: './generate-radio-input.component.html',
  styleUrls: ['./generate-radio-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: GenerateRadioInputComponent,
    multi: true
  }]
})
export class GenerateRadioInputComponent implements ControlValueAccessor, OnInit {
  @Input() options: any[] = [];
  @Input() maxNumber: number = 9999;
  @Input() minNumber: number = 1;
  @Input() maxLength: number = 4;
  @Input() defautlOption!: string;
  selectedOption!: string;
  previousOption: string = null;
  numberValue!: number | string;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  public ngControl: NgControl | null = null;

  constructor(@Optional() private injector: Injector) {}

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private isVaildValOption(val: string): boolean {
    return this.options.findIndex(({ value }) => value == val) != -1;
  }

  private handleWriteValue(inputVal: string=""): void {
    if (inputVal) {
      const parts = inputVal.split(',');

      if (!this.isVaildValOption(parts[0])) {
        this.selectedOption = this.defautlOption;
        this.previousOption = null;
        this.numberValue = null;
      } else {
        this.selectedOption = this.isVaildValOption(parts[0]) ? parts[0]: null;
        this.previousOption = this.selectedOption;
        this.numberValue = !isNaN(parseFloat(parts[1])) ? parseFloat(parts[1]) : null;
      }
    }
  }

  writeValue(value: string): void {
    if (!value){
      value = "empty";
    }
    if (this.defautlOption && !value) {
      this.handleWriteValue(this.defautlOption);
    } else {
      this.handleWriteValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue(): void {
    let formattedValue = "";

    if (this.selectedOption || this.numberValue) {
      formattedValue = `${this.selectedOption},${this.numberValue}`;
    }
    this.onChange(formattedValue);
    this.markAsDirty();
  }

  onOptionChange(option: string): void {
    if (option == this.previousOption){
      option = null;
    }
    this.previousOption = option;
    this.selectedOption = option;
    this.setValue();
  }

  onNumberChange(value: string): void {
    this.numberValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;
    this.setValue();
  }

  private markAsDirty(): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.markAsDirty();
    }
  }

  get isInvalid(): boolean {
    return this.ngControl && this.ngControl.invalid && this.ngControl.dirty;
  }
}
