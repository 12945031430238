import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EXEC_TYPE } from 'src/app/shared/html-parts/upload-file/csv-upload/constant';
import { FILE_EXTENSION } from 'src/app/shared/constant/constant';
import { Table } from 'primeng/table'; 
import {
  API_URL_FILE_UPLOAD,
} from 'manager/http-constants_key';


import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { API_URL_ITEM } from 'manager/http-constants_key';
import { CommonService } from 'src/app/shared/service/common.service';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CONSTANT } from 'src/app/shared/constant/constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { DEPARTMENT$DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';

@Component({
  selector: 'file-upload-master',
  templateUrl: './file-upload-master.component.html',
  styleUrls: ['./file-upload-master.component.scss'],
})

/**
 * ファイル一括登録(マスタ)画面
 */
export class FileUploadMasterComponent implements OnInit {

  // 許容ファイル拡張子
  toleranceExtension: string = FILE_EXTENSION.CSV;

  @ViewChild(Table) table: Table;
  
  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnJobLog: any[] = new Array();
  // 処理履歴情報格納先
  searchResultsList: any[] = new Array();
  // ファイル種別
  fileTypeList: any[] = new Array();
  // 選択ファイル種別
  selectFileType: string = null;
  // アップロードファイル
  uploadedFiles: any[] = [];
  // 処理JOBID
  sendJobId: string = null;

  count: number = 0;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private router: Router
  ) {}

  ngOnInit(): void {
    // ファイル種別を定義
    if (DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == this.loginUser.department_type) {
      // 発注一般・管理ユーザーの場合
      this.fileTypeList = [{type: "deliveryMaster", type_code: "M2", type_name: "届先マスタ"}];
    } else {
      // 発注一般・管理ユーザー以外の場合
      this.fileTypeList = [{type: "itemMaster", type_code: "M1", type_name: "資材マスタ"}
                          ,{type: "deliveryMaster", type_code: "M2", type_name: "届先マスタ"}
                          ,{type: "groupMaster", type_code: "M3", type_name: "組織マスタ"}
                          ,{type: "userMaster", type_code: "M4", type_name: "ユーザーマスタ"}
                          ,{type: "newsMaster", type_code: "M5", type_name: "お知らせマスタ"}
                          ,{type: "businessDayMaster", type_code: "M6", type_name: "営業日マスタ"}];
    }
  }

   /**
   * 画面遷移
   */
    toFileUploadHistory(): void{
      this.router.navigateByUrl('/pages/secretariat/fileUploadHistory');
    }

   /**
   * ファイルアップロード
   * @param event 画像情報
   * @param fileUpload ボタン動作状態
   */
  public uploadFile(event) {
    // ラジオボタンの選択値を取得
    if(!this.selectFileType){
      // 未選択エラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(
            MESSAGE_CODE.E00016,
            'ファイル種別'),
        })
      );
      return;
    }
    if(event.files.length > 1){
      // アップロード上限エラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00025),
        })
      );
      return;
    }
    
    // 拡張子を取得
    const imageExtension = event.files[0].name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    // 拡張子が".csv" or ".tsv"か否か判定
    if (
      !(
        FILE_EXTENSION.CSV == imageExtension ||
        FILE_EXTENSION.TSV == imageExtension
      )
    ) {
      // 拡張子が".csv" or ".tsv"以外の場合

      // 拡張子エラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00012),
          detail: this.commonService.msg(
            MESSAGE_CODE.E00013,
            'csv又はtsv'
          ),
        })
      );

      // ボタン動作状態アップロードファイルをクリア
      this.uploadedFiles = new Array();

      // 処理を終了
      return;
    }
    // 実行前確認
    if(!window.confirm(event.files[0].name + " をアップロードしますか？")){
      return;
    }
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00005),
      })
    );

    // ファイル登録
    this.commonService.getBase64(event.files[0]).then((fileInformation) => {
      var fileInfo = fileInformation.replace(/^data:\w+\/\w+;base64,/, '');
      // ファイル情報(base64)返却後、ファイル登録を実施
      this.dbOperationService
      .insertFileSpecifyUrl(event.files[0].name, fileInfo, this.selectFileType, API_URL_FILE_UPLOAD)
      .subscribe((response) => {
        console.log(this.commonService.msg(MESSAGE_CODE.I00013));
        // 登録完了メッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.SUCCESS,
            summary: this.commonService.msg(MESSAGE_CODE.I00013),
            detail: this.commonService.msg(MESSAGE_CODE.T00013),
          })
        );
        // job_Idを設定
        this.sendJobId = response.body.job_id;
        // 完了メッセージを表示
        document.getElementById("complate_msg").style.visibility = "visible";
        // ボタン動作状態アップロードファイルをクリア
        this.uploadedFiles = new Array();
      }, (error) => {
        // 登録失敗メッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00004),
            detail: this.commonService.msg(error),
          })
        );
      });
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();
    });
  }
}
