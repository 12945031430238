/**
 * API URL取得用キー
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS = 'USER_AUTHINFOS';
// ヘッダー項目生成
export const HEADER_LIST = 'HEADER_LIST';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT = 'API_URL_VALIDATION_RESULT';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT = 'API_URL_MULTIVALIDATION_RESULT';
// 画像登録
export const API_URL_UPLOAD_IMAGE = 'API_URL_UPLOAD_IMAGE';
// ファイル登録
export const API_URL_UPLOAD_CSV = 'API_URL_UPLOAD_CSV';
// ファイル情報登録
export const API_URL_REGIST_DATA = 'API_URL_REGIST_DATA';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT = 'API_URL_RUN_COUNT';
// 辞書値取得
export const API_URL_DICVALUES = 'API_URL_DICVALUES';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT = 'API_URL_OUTPUT';

/**
 * マスタ画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 = 'API_URL_INFORMATIONS2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION = 'API_URL_INFORMATION';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION = 'API_URL_SEARCH_INFORMATION';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION = 'API_URL_INPUT_INFORMATION';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER = 'API_URL_USER';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER = 'API_URL_SEARCH_USER';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER = 'API_URL_INPUT_USER';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY = 'API_URL_BIZDAY';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY = 'API_URL_SEARCH_BIZDAY';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY = 'API_URL_INPUT_BIZDAY';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT = 'API_URL_DEPARTMENT';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT = 'API_URL_SEARCH_DEPARTMENT';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT = 'API_URL_INPUT_DEPARTMENT';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK = 'API_URL_STOCK';

// 届先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY = 'API_URL_DELIVERY';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY = 'API_URL_SEARCH_DELIVERY';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY = 'API_URL_INPUT_DELIVERY';

// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM = 'API_URL_ITEM';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM = 'API_URL_SEARCH_ITEM';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM = 'API_URL_INPUT_ITEM';

/**
 * セット品画面
 */
// セット品マスタ管理_登録画面(GET)
export const API_URL_INPUT_SET = 'API_URL_INPUT_SET';
// セット品マスタ管理_詳細画面(メイン)(GET)
export const API_URL_DISPLAY_SET_MAIN = 'API_URL_DISPLAY_SET_MAIN';
// セット品マスタ管理_詳細画面(サブ)(GET)
export const API_URL_DISPLAY_SET_ITEM = 'API_URL_DISPLAY_SET_ITEM';
// セット品マスタ管理_検索画面(GET)
export const API_URL_SEARCH_SET = 'API_URL_SEARCH_SET';
// セット品マスタ管理_資材検索部(GET)
export const API_URL_SERACH_SET_ITEM = 'API_URL_SERACH_SET_ITEM';
// セット品マスタ管理_一覧画面(GET)
export const API_URL_SET = 'API_URL_SET';
// セット品マスタ管理_複製画面(メイン)(GET)
export const API_URL_DUP_SET_MAIN = 'API_URL_DUP_SET_MAIN';
// セット品マスタ管理_複製画面(サブ)(GET)
export const API_URL_DUP_SET_ITEM = 'API_URL_DUP_SET_ITEM';

/**
 * 注文画面
 */
// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER = 'API_URL_ORDER';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER = 'API_URL_SEARCH_ORDER';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER = 'API_URL_INPUT_ORDER';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT = 'API_URL_USER_DEPARTMENT';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER = 'API_URL_WORK_ORDER';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS = 'API_URL_ORDER_STATUS';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL = 'API_URL_ORDER_CANCEL';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER = 'API_URL_INPUT_WORK_ORDER';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM = 'API_URL_INPUT_ORDER_ITEM';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE = 'API_URL_ORDER_DELIVERIE';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE = 'API_URL_SEARCH_ORDER_DELIVERIE';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE = 'API_URL_WORK_ORDER_DELIVERIE';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'API_URL_WORK_ORDER_ITEM_STOCK_INFO';
  // 発注管理_販促資材／REST API(GET:一覧)
  export const API_URL_ORDER_ITEM = 'API_URL_ORDER_ITEM';
  // 発注管理_販促資材／REST API(GET:詳細)
  export const API_URL_ORDER_ITEM_DETAIL = 'API_URL_ORDER_ITEM_DETAIL';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM = 'API_URL_SEARCH_ORDER_ITEM';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM = 'API_URL_WORK_ORDER_ITEM';
// 発注管理／注文関連WORK削除(DELETE)
export const API_URL_SUB_WORK_ORDERS = 'API_URL_SUB_WORK_ORDERS';

// 注文情報/一括承認(PUT)
export const API_URL_APPROVAL_ORDER = 'API_URL_APPROVAL_ORDER';

/**
 * 在庫メンテナンス画面
 */
// 在庫メンテナンス管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_STOCK_MAINTENANCE = 'API_URL_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／検索画面(GET)
export const API_URL_SEARCH_STOCK_MAINTENANCE =
  'API_URL_SEARCH_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／登録画面(GET)
export const API_URL_INPUT_STOCK_MAINTENANCE =
  'API_URL_INPUT_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／更新(PUT)
export const API_URL_STOCK_MAINTENANCES_CANCEL =
  'API_URL_STOCK_MAINTENANCES_CANCEL';

// 製作指示／REST API(GET:一覧/GET:詳細)
export const API_URL_STOCK_PLANS = 'API_URL_STOCK_PLANS';
// 製作指示／検索画面(GET)
export const API_URL_SEARCH_STOCK_PLANS = 'API_URL_SEARCH_STOCK_PLANS';
// 製作指示／登録画面(GET)
export const API_URL_INPUT_STOCK_PLANS = 'API_URL_INPUT_STOCK_PLANS';
// 製作指示／製作指示登録(入庫予定登録)
export const API_URL_STOCK_PLAN = 'API_URL_STOCK_PLAN';

/**
 * 計画情報画面 利用しない
 */
// 計画情報一覧／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_EVENT = 'API_URL_EVENT';
// 計画情報一覧／検索画面(GET)
export const API_URL_SEARCH_EVENT = 'API_URL_SEARCH_EVENT';
// 計画情報一覧／登録画面(GET)
export const API_URL_INPUT_EVENT = 'API_URL_INPUT_EVENT';
// 計画情報／更新(PUT)
export const API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS =
  'API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS';

// 計画数入力・確認／イベント名プルダウン(GET)
export const API_URL_QUANTITY_PLAN_EVENTS = 'API_URL_QUANTITY_PLAN_EVENTS';

// 計画数入力／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_QUANTITY_PLANS = 'API_URL_QUANTITY_PLANS';
// 計画数入力／検索画面(GET)
export const API_URL_SEARCH_QUANTITY_PLANS = 'API_URL_SEARCH_QUANTITY_PLANS';
// 計画数入力／登録画面(GET)
export const API_URL_INPUT_QUANTITY_PLANS = 'API_URL_INPUT_QUANTITY_PLANS';

// 計画数確認／REST API(GET:一覧/GET:詳細)
export const API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS';
// 計画数確認／検索画面(GET)
export const API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS';

/**
 * 帳票画面　利用しない
 */
// 棚卸帳票／検索画面(GET)
export const API_URL_SEARCH_INVENTORY_SUMMARYS =
  'API_URL_SEARCH_INVENTORY_SUMMARYS';
// 棚卸帳票／IMD棚卸集計表作成・ダウンロード(GET)
export const API_URL_INVENTORY_SUMMARYS = 'API_URL_INVENTORY_SUMMARYS';
// IMD費用明細帳票／検索画面(GET)
export const API_URL_SEARCH_COST_SUMMARYS = 'API_URL_SEARCH_COST_SUMMARYS';
// IMD費用明細帳票／IMD費用明細作成・ダウンロード(GET)
export const API_URL_COST_SUMMARYS = 'API_URL_COST_SUMMARYS';

/**
 * 業務バリデーションチェック
 */
// 販促資材マスタ／編集時業務チェック(POST) 利用しない
export const API_URL_CHECK_EDIT_ITEM_RESULT = 'API_URL_CHECK_EDIT_ITEM_RESULT';
// 注文情報履歴／編集時業務チェック(POST)
export const API_URL_CHECK_EDIT_ORDER_RESULT =
  'API_URL_CHECK_EDIT_ORDER_RESULT';

/**
 * ファイル一括登録
 */
// ファイル一括登録／ファイルアップロード(POST)
export const API_URL_FILE_UPLOAD = 'API_URL_FILE_UPLOAD';
// ファイル一括登録状況／REST API(GET:一覧)
export const API_URL_FILE_UPLOAD_HISTORY = 'API_URL_FILE_UPLOAD_HISTORY';
// ファイル一括登録状況(JOB)／検索画面(GET)
export const API_URL_SEARCH_FILE_UPLOAD_HISTORY = 'API_URL_SEARCH_FILE_UPLOAD_HISTORY';
// ファイル一括登録状況詳細(JOB)／REST API(GET:一覧)
export const API_URL_UPLOAD_DETAIL = 'API_URL_UPLOAD_DETAIL';