import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CSV_UPLOAD_DATA } from './csv-upload';
import { CsvUploadDataComponent } from './csv-upload-data/csv-upload-data.component';
import { CsvErrorMessageComponent } from './csv-error-message/csv-error-message.component';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadData, LoadingState } from '../../loading/loading-state';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MessageData } from '../../message-common/message-data';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss'],
})

/**
 * CSVファイル登録
 */
export class CsvUploadComponent implements OnInit {
  /** CSVファイル登録エラーメッセージ */
  @ViewChild(CsvErrorMessageComponent)
  csvErrorMessageComponent: CsvErrorMessageComponent;

  /** CSVファイル確認画面 */
  @ViewChild(CsvUploadDataComponent)
  csvUploadDataComponent: CsvUploadDataComponent;

  // インプット.CSVファイル登録ボタン名称
  @Input() buttonName: string;

  // インプット.対象テーブル(登録や更新を行うテーブル)
  @Input() tableId: string;

  // インプット.CSVファイル登録実行タイプ
  @Input() execType: string;

  // インプット.CSVファイル確認画面ヘッダーテンプレート
  @Input() csvUploadHeaderTemplateId: number;

  // インプット.許容ファイル拡張子(.csv or .tsv の指定したファイル拡張子のみ設定可能)
  // 指定なし 又は　設定可能以外の文字列の場合、.csv と .tsv　の両拡張子を許容
  @Input() toleranceExtension: string;

  // インプット.バックアップ用ファイル名
  // 指定なしの場合、デフォルトファイル名をセット
  @Input() fileName: string;

  // インプット.登録追加データ
  // ※登録用データに対する情報を追加
  @Input() addData: Object;

  // アウトプット.取り込み状態フラグ(true:データ取り込み中,false:取り込みデータなし)
  @Output() captureFlag: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {}

  /**
   * csvファイル確認表示
   * @param csvUploadData CSVファイル登録情報オブジェクト
   */
  public outputCsvUploadData(csvUploadData: CSV_UPLOAD_DATA) {
    // csvファイル確認表示
    this.csvUploadDataComponent.initial(csvUploadData);
  }

  /**
   * csvファイルエラーメッセージ表示
   * @param csvErrorMessage CSVエラーメッセージオブジェクト
   */
  public outputCsvErrorMessage(csvErrorMessageList: Object[]) {
    // CSVファイル登録エラーメッセージ表示
    this.csvErrorMessageComponent.initial(csvErrorMessageList);

    // CSVファイル確認クリア
    this.csvUploadDataComponent.clear();
  }

  /**
   * データ取り込み状態フラグを返却
   * @param captureFlag データ取り込み状態フラグ
   */
  public outputCaptureFlag(captureFlag: boolean) {
    // データ取り込みフラグをOFF
    this.captureFlag.emit(captureFlag);
  }

  /**
   * csvファイル情報登録
   * @param csvUploadData CSVファイル登録情報
   */
  public insertData(csvUploadData: CSV_UPLOAD_DATA) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00010),
      }),
      LOADING_KEY.FILE_UPLOAD
    );

    // 登録フラグをセット
    csvUploadData.setRegistFlag = 1;

    /* csvファイル情報登録を実施 */
    {
      // 入力項目値確認用API
      this.dbOperationService
        .insertFileData(csvUploadData)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.FILE_UPLOAD);

          // レスポンスを判定
          if (
            this.messageData.resultResponseToastMessage(
              response,
              null,
              this.commonService.msg(MESSAGE_CODE.I00007)
            )
          ) {
            // レスポンスが正常終了の場合

            // csvファイル確認画面クリア
            this.csvUploadDataComponent.clear();
          }
        });
    }
  }
}
