import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL_INFORMATIONS2 } from 'manager/http-constants_key';
import { TOP } from 'manager/template-constant';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})
export class NoticeListService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * お知らせ確認取得
   * @param informationType カテゴリID
   */
  getData(informationType: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_INFORMATIONS2,
      '?TemplateID=',
      TOP.INFORMATION,
      '&information_type=',
      informationType
    );

    // お知らせ一覧情報を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
