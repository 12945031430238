import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { API_URL_COST_SUMMARYS } from 'manager/http-constants_key';
import { SEARCH_INFORMATIONS_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT } from 'src/app/shared/constant/constant';
import {
  FILE_IDENTIFIER,
  REQUEST_IDENTIFIER,
} from 'src/app/shared/html-parts/export-file/constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { SEARCH_COST_SUMMARYS_API_CONSTANT } from './constant';
import { CostDetailsReportService } from './cost-details-report.service';

@Component({
  selector: 'app-cost-details-report',
  templateUrl: './cost-details-report.component.html',
  styleUrls: ['./cost-details-report.component.scss'],
})

/**
 * 費用明細帳票出力画面
 */
export class CostDetailsReportComponent implements OnInit {
  // 画面.検索フォーム
  searchForm: FormGroup = this.formBuilder.group(Object());

  // 画面.検索項目
  searchDataList: any[] = new Array();

  // 画面.検索項目生成
  searchItemList: any[] = new Array();

  // レポート種別
  rkind: string;

  constructor(
    private loadingState: LoadingState,
    private costDetailsReportService: CostDetailsReportService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private exportFileService: ExportFileService
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('outputSearchItem');

    /* 検索項目取得処理 */
    {
      // 検索項目取得取得
      this.costDetailsReportService.getSearchData().subscribe((response) => {
        // 取得した検索項目を格納
        this.searchDataList = response.body;

        // 1行目のレポート種別を格納
        this.rkind = response.body[0].rkind;

        // 検索項目生成を実施
        this.outputSearchItem();
      });
    }
  }

  /**
   * 業務変更プルダウン
   */
  public reportChange() {
    // 検索項目生成を実施
    this.outputSearchItem();
  }

  /**
   * 検索項目生成
   */
  private outputSearchItem() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('outputSearchItem');

    // 画面.入力フォームを初期化
    this.searchItemList = new Array();
    this.searchForm = this.formBuilder.group(Object());

    // レポート種別に紐づく検索項目を取得
    const searchItemList = this.commonService.getArrayObjectValue(
      this.searchDataList,
      SEARCH_COST_SUMMARYS_API_CONSTANT.RKIND,
      SEARCH_COST_SUMMARYS_API_CONSTANT.VALUE,
      this.rkind
    );

    // 検索フォーム用コントロール作成
    for (const searchItem of searchItemList) {
      // 検索項目タイプの判定
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE == searchItem.search_type ||
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == searchItem.search_type
      ) {
        // 検索項目タイプが"date" or "number"の場合
        this.searchForm.addControl(
          searchItem.column_id + '_FROM',
          this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
        );

        this.searchForm.addControl(
          searchItem.column_id + '_TO',
          this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
        );
      } else {
        // 検索項目タイプが"date" or "number"以外の場合
        this.searchForm.addControl(
          searchItem.column_id,
          this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
        );
      }
    }

    // 検索項目情報を画面.検索項目生成に格納
    this.searchItemList = searchItemList;

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3, 'outputSearchItem');
  }

  /**
   * 帳票出力処理
   */
  public async outputReport() {
    /* 検索用リクエストオブジェクト作成 */
    // 検索フォームオブジェクトを生成
    let searchForm: object = new Object();

    // レポート種別を格納
    searchForm['rkind'] = this.rkind;

    // 検索フォームオブジェクトに検索条件を格納
    searchForm = Object.assign(searchForm, this.searchForm.value);

    // 帳票出力を実施
    this.exportFileService.exportFile(
      API_URL_COST_SUMMARYS,
      FILE_IDENTIFIER.EXCEL,
      REQUEST_IDENTIFIER.GET,
      searchForm
    );
  }

  /**
   * クリアボタン押下
   */
  public reset() {
    // デフォルト入力値オブジェクトを生成
    let defaultInputValue: object = new Object();

    // 検索項目情報をループ
    for (const searchItem of this.searchItemList) {
      // 検索項目タイプの判定
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE == searchItem.search_type ||
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == searchItem.search_type
      ) {
        // 検索項目タイプが"date" or "number"の場合
        defaultInputValue[searchItem.column_id + '_FROM'] =
          CONSTANT.EMPTY_STRING;

        defaultInputValue[searchItem.column_id + '_TO'] = CONSTANT.EMPTY_STRING;
      } else {
        // 検索項目タイプが"date" or "number"以外の場合
        defaultInputValue[searchItem.column_id] = CONSTANT.EMPTY_STRING;
      }
    }

    // フォーム入力値をクリア
    this.searchForm.reset(defaultInputValue);
  }
}
