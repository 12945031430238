<div class="menu p-d-flex">
  <!-- 発注管理リスト表示領域 -->
  <div class="breadcrumb">
    <!-- 発注管理リスト -->
    <app-breadcrumb></app-breadcrumb>
  </div>
  <!-- TODO 森永用 -->
  <!-- ヒントメッセージ -->
  <!--
  <button
    pButton
    icon="pi pi-question"
    class="
      p-button-warning
      p-button-sm
      p-button-raised
      p-button-rounded
      p-button-outlined
      tipsMessageButton
    "
    appendTo="body"
    (mouseenter)="tipsMessage.toggle($event)"
  ></button>
  <p-overlayPanel #tipsMessage>
    <ng-template pTemplate>
      <label class="tipsMessage">
        ①遠隔地（北海道、新潟、九州、沖縄）の場合は最短納品日＋１日かかります。
        最短リードタイムは発注から2日となります。<br />
        ②納品数量が多い場合、納品日を通常より先に設定する必要があります。<br />
        ③入力した納品予定日に納品できない場合は、事務局から連絡が来ます。<br />
      </label>
    </ng-template>
  </p-overlayPanel>
  -->
</div>

<!-- 入力エラーメッセージ出力領域 -->
<p-messages severity="error" *ngIf="inputErrorMessageList.length">
  <!-- 入力エラーメッセージが存在する場合、表示 -->
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
        <div *ngFor="let inputErrorMessage of inputErrorMessageList">
          案件コード：
          <!-- 販促資材コード -->
          {{ inputErrorMessage.itemId }}
          &nbsp;
          <!-- 届先ID -->
          委託先製造関連ID：
          <!-- 届先ID -->
          {{ inputErrorMessage.deliveryId }}
          <br />

          <!-- エラーメッセージリスト分ループ-->
          <div *ngFor="let errorMessage of inputErrorMessage.errorMessageList">
            <!-- カラム名称 -->
            {{ errorMessage.columnName }}：
            <!-- エラーメッセージ -->
            {{ errorMessage.message }}
            <br />
          </div>
          <br />
        </div>
      </div>
    </div>
  </ng-template>
</p-messages>

<!-- 入力フォーム -->
<!-- 一覧画面表示領域-->
<p-table
  [value]="searchResultsList"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
  [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
  styleClass="p-datatable-striped order-datatable"
  selectionMode="single"
  stateStorage="session"
  *ngIf="!confirmationFlag"
  [stateKey]="quantityDataTableSessionKey"
  [(selection)]="selected"
  [autoLayout]="true"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-start">
      <!-- 確認-->
      <button
        type="button"
        pButton
        label="確認"
        (click)="onClickConfirmation()"
        class="p-mr-2 button"
      ></button>
      <!-- 一括入力 -->
      <button
        type="button"
        pButton
        label="一括入力"
        class="p-mr-2 button"
        (click)="onClickBulkInput()"
      ></button>
      <!-- リセットボタン-->
      <button
        type="button"
        pButton
        label="リセット"
        class="p-mr-2 button"
        (click)="onClickReset()"
      ></button>
    </div>
  </ng-template>

  <!-- 一覧画面ヘッダー表示-->
  <ng-template pTemplate="header" [formGroup]="bulkInputForm">
    <tr class="p-col-2">
      <!-- APIヘッダーの表示 -->
      <ng-container *ngFor="let column of columnOrder">
        <!-- カラム判定 -->
        <!-- 画像以外の場合 -->
        <th
          [pSortableColumn]="column.field"
          *ngIf="column.input_type != 'IMAGE'"
        >
          {{ column.header }}
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </th>
        <!-- 画像の場合 -->
        <th *ngIf="column.input_type == 'IMAGE'">
          {{ column.header }}
        </th>
      </ng-container>

      <!-- 注文金額
      <th>注文金額</th> -->

      <!-- API入力項目ヘッダーの表示 -->
      <ng-container *ngFor="let inputColumn of inputColumnOrder">
        <th>
          {{ inputColumn.column_name }} <br />

          <!-- 入力項目タイプを判定 -->
          <!-- テキスト -->
          <input
            [name]="inputColumn.column_id"
            type="text"
            [maxlength]="inputColumn.column_hsize"
            [formControlName]="inputColumn.column_id"
            *ngIf="inputColumn.input_type == 'text'"
            pInputText
          />
          <!-- 日付 -->
          <p-calendar
            [name]="inputColumn.column_id"
            [showIcon]="true"
            [showButtonBar]="true"
            [yearNavigator]="true"
            [monthNavigator]="true"
            dateFormat="yy/mm/dd"
            dataType="string"
            appendTo="body"
            yearRange="2000:2999"
            [formControlName]="inputColumn.column_id"
            *ngIf="inputColumn.input_type == 'date'"
            translate
          >
          </p-calendar>
          <!-- 数字 -->
          <input
            [name]="inputColumn.column_id"
            type="number"
            [formControlName]="inputColumn.column_id"
            *ngIf="inputColumn.input_type == 'number'"
            pInputText
          />
          <!-- シングルセレクト -->
          <p-dropdown
            [name]="inputColumn.column_id"
            [options]="inputColumn.column_code_list_multi"
            placeholder="　"
            optionLabel="name"
            [showClear]="true"
            [filter]="true"
            emptyFilterMessage="検索結果が存在しません"
            appendTo="body"
            [formControl]="bulkInputForm.controls[inputColumn.column_id]"
            *ngIf="inputColumn.input_type == 'single_select'"
          >
          </p-dropdown>
          <!-- テキストエリア -->
          <textarea
            [name]="inputColumn.column_id"
            [maxlength]="inputColumn.column_hsize"
            [formControlName]="inputColumn.column_id"
            *ngIf="inputColumn.input_type == 'textarea'"
            pInputTextarea
          ></textarea>
        </th>
      </ng-container>
      <!-- チェックボックスヘッダーの表示 -->
      <!--
      <th class="inputCheckbox" *ngIf="3 != category1">
        緊急<br />
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      -->
      <!-- 操作ヘッダーの表示 -->
      <th class="deleteOperation">
        <p>操作</p>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-searchResults [formGroup]="inputForm">
    <!-- 子フォームグループ -->
    <tr [formGroupName]="searchResults[getPkeyColumn()]">
      <!-- 一覧表示-->
      <ng-container *ngFor="let column of columnOrder">
        <!-- 一覧項目値表示 -->
        <!-- カラム判定 -->
        <td [ngSwitch]="column?.input_type">
          <span *ngSwitchCase="'IMAGE'">
            <!-- 画像の場合 -->
            <img [src]="searchResults[column.field]" alt="image" />
          </span>
          <span *ngSwitchDefault>
            <!-- TODO 特定カラムID判定は暫定対応-->
            <span *ngIf="column.field == 'item_tanka'; else PkExcepting">
              {{ searchResults[column.field] | number }}
            </span>
            <!-- 特定カラムID以外の場合 -->
            <ng-template #PkExcepting>
              {{ searchResults[column.field] }}
            </ng-template>
          </span>
        </td>
      </ng-container>
      

      <!-- 注文金額
      <td>
        {{
          calculationOrderCost(
            searchResults.item_tanka,
            inputForm.controls[searchResults[getPkeyColumn()]].value.order_num
          ) | number
        }}円
      </td> -->

      <!-- 入力項目表示 -->
      <ng-container *ngFor="let inputItem of inputItemList">
        <!-- 入力項目タイプを判定 -->
        <!-- テキスト -->
        <td *ngIf="inputItem.input_type == 'text'">
          <input
            [name]="inputItem.column_id"
            type="text"
            [maxlength]="inputItem.column_hsize"
            [class.errorItem]="
              checkErrorItem(
                searchResults[getPkeyColumn()],
                inputItem.column_id
              )
            "
            [formControlName]="inputItem.column_id"
            pInputText
          />
        </td>
        <!-- 日付 -->
        <td class="inputDate" *ngIf="inputItem.input_type == 'date'">
          <p-calendar
            [name]="inputItem.column_id"
            [showIcon]="true"
            [showButtonBar]="true"
            [yearNavigator]="true"
            [monthNavigator]="true"
            dateFormat="yy/mm/dd"
            dataType="string"
            appendTo="body"
            yearRange="2000:2999"
            [inputStyleClass]="
              checkErrorItem(
                searchResults[getPkeyColumn()],
                inputItem.column_id
              )
                ? 'errorItem'
                : ''
            "
            [formControlName]="inputItem.column_id"
            translate
          >
          </p-calendar>
        </td>
        <!-- 数字 -->
        <td class="inputNumber" *ngIf="inputItem.input_type == 'number'">
          <input
            [name]="inputItem.column_id"
            type="number"
            [class.errorItem]="
              checkErrorItem(
                searchResults[getPkeyColumn()],
                inputItem.column_id
              )
            "
            [formControlName]="inputItem.column_id"
            pInputText
          />
        </td>
        <!-- シングルセレクト -->
        <td
          class="inputDropdown"
          *ngIf="inputItem.input_type == 'single_select'"
        >
          <p-dropdown
            [name]="inputItem.column_id"
            [options]="inputItem.column_code_list_multi"
            placeholder="　"
            optionLabel="name"
            [showClear]="true"
            [filter]="true"
            emptyFilterMessage="検索結果が存在しません"
            appendTo="body"
            [styleClass]="
              checkErrorItem(
                searchResults[getPkeyColumn()],
                inputItem.column_id
              )
                ? 'errorItem'
                : ''
            "
            [formControl]="
              inputForm.controls[searchResults[getPkeyColumn()]].controls[
                inputItem.column_id
              ]
            "
          >
          </p-dropdown>
        </td>
        <!-- テキストエリア -->
        <td *ngIf="inputItem.input_type == 'textarea'">
          <textarea
            [name]="inputItem.column_id"
            [maxlength]="inputItem.column_hsize"
            [class.errorItem]="
              checkErrorItem(
                searchResults[getPkeyColumn()],
                inputItem.column_id
              )
            "
            [formControlName]="inputItem.column_id"
            pInputTextarea
          ></textarea>
        </td>
      </ng-container>

      <!-- チェックボックス -->
      <!--
      <td class="inputCheckbox" *ngIf="3 != category1">
        <p-tableCheckbox [value]="searchResults"></p-tableCheckbox>
      </td>
      -->

      <!-- 操作表示 -->
      <td>
        <!-- 削除ボタン -->
        <button
          pButton
          (click)="delete(searchResults)"
          icon="pi pi-trash"
          class="p-button-rounded p-button-danger"
          pTooltip="削除"
          tooltipPosition="bottom"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- 入力確認 -->
<!-- 確認情報出力領域 -->
<p-table
  [value]="quantityRequest"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
  [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
  styleClass="p-datatable-striped order-datatable"
  selectionMode="single"
  stateStorage="session"
  [stateKey]="quantityDataTableSessionKey"
  *ngIf="confirmationFlag"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-jc-start">
      <!-- 登録 -->
      <button
        type="button"
        pButton
        label="登録"
        (click)="onClickRegist()"
        class="p-mr-2 button"
      ></button>
      <!-- 戻るボタン-->
      <button
        type="button"
        pButton
        label="戻る"
        class="p-mr-2 button"
        (click)="confirmationFlag = false"
      ></button>
    </div>
  </ng-template>

  <!-- 一覧画面ヘッダー表示-->
  <ng-template pTemplate="header">
    <tr class="p-col-2">
      <!-- APIヘッダーの表示 -->
      <ng-container *ngFor="let column of columnOrder">
        <th>
          {{ column.header }}
        </th>
      </ng-container>

      <!-- 注文金額 -->
      <!-- <th>注文金額</th> -->

      <!-- API入力項目ヘッダーの表示 -->
      <ng-container *ngFor="let inputColumn of inputItemList">
        <th>
          {{ inputColumn.column_name }}
        </th>
      </ng-container>

      <!-- チェックボックスヘッダーの表示 -->
      <!--
      <th class="inputCheckbox" *ngIf="3 != category1">緊急</th>
      -->
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-quantityRequestItem>
    <tr>
      <!-- 一覧表示-->
      <ng-container *ngFor="let column of columnOrder">
        <!-- 一覧項目値表示 -->
        <td>
          <!-- TODO 特定カラムID判定は暫定対応-->
          <span *ngIf="column.field == 'item_tanka'; else PkExcepting">
            {{ quantityRequestItem[column.field] | number }}
          </span>
          <!-- 特定カラムID以外の場合 -->
          <ng-template #PkExcepting>
            {{ quantityRequestItem[column.field] }}
          </ng-template>
        </td>
      </ng-container>

      <!-- 注文金額 -->
      <!-- <td>
        {{
          calculationOrderCost(
            quantityRequestItem.item_tanka,
            quantityRequestItem.order_num
          ) | number
        }}円
      </td> -->

      <!-- 入力項目表示 -->
      <ng-container *ngFor="let inputItem of inputItemList">
        <td>
          {{
            getInputFormConfirmation(
              inputItem.column_id,
              quantityRequestItem[inputItem.column_id]
            )
          }}
        </td>
      </ng-container>

      <!-- チェックボックス -->
      <!-- <td class="inputCheckbox" *ngIf="3 != category1"> -->
        <!-- チェック状態の場合 -->
        <!-- <i class="pi pi-check" *ngIf="1 == quantityRequestItem[checkBox]"></i> -->
      <!-- </td> -->
    </tr>
  </ng-template>
</p-table>

<!-- ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  class="dialog"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
