import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { environment_i18n } from 'manager/environment';
import { Routing } from './shared/service/interceptor.service';
import { Title } from '@angular/platform-browser';
import { TITLE } from '../../manager/environment';
import { ActivationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // メッセージ用ヘッダー判定フラグ
  headerFlag: boolean;

  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private routing: Routing,
    private titleService: Title,
    private readonly _router: Router
  ) {
    // ページのヘッダ情報を変更
    this.titleService.setTitle(TITLE.TITLE_BAR);

    // ルーティング遷移時のチェック処理
    this.routing;

    // ルーティング遷移時のコンポーネント判定
    this._router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe((event: any) => {
        // 遷移先コンポーネントがMessageComponentの場合、ヘッダーのアイコンを非表示にする
        this.headerFlag =
          event.snapshot.routeConfig.component.name == 'MessageComponent'
            ? false
            : true;
      });

    this.translateService.setDefaultLang(environment_i18n.defaultLang);

    // PrimeNGを日本語化
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit() {}
}
