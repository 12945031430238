<div class="ui-fluid" [class.isInvalid]="isInvalid">
  <div class="p-field-radiobutton" *ngFor="let column of options">
    <p-radioButton
      [(ngModel)]="selectedOption"
      [label]="column.name"
      [value]="column.value"
      (ngModelChange)="onOptionChange(column.value)"
    >
    </p-radioButton>
  </div>

  <p-inputNumber
    [(ngModel)]="numberValue"
    (ngModelChange)="onNumberChange($event)"
    mode="decimal"
    inputId="withoutgrouping"
    [useGrouping]="false"
    [max]="maxNumber"
    [maxlength]="maxLength"
  >
  </p-inputNumber>
</div>
