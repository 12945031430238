<!-- 発注管理リスト表示領域 -->
<div class="breadcrumb">
  <!-- 発注管理リスト -->
  <app-breadcrumb [selectionLine]="cartSelected.length"></app-breadcrumb>
</div>

<!-- 一覧画面-->
<mat-drawer-container class="main" autosize>
  <!-- 一覧画面表示領域-->
  <p-dataView #dv
              [value]="searchResultsList"
              [paginator]="true"
              [rows]="10"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
              [rowsPerPageOptions]="[2, 10, 50, 100, 500, 1000]"
              styleClass="p-datatable-striped order-datatable"
              selectionMode="single"
              layout="list"
              [sortField]="sortField"
              [sortOrder]="sortOrder">
    <ng-template pTemplate="header">
      <div class="dv-header-button">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
            <!-- 検索画面表示-->
            <button
              type="button"
              pButton
              icon="pi pi-search"
              (click)="search.toggle()"
              class="p-mr-2"
              pTooltip="検索"
              tooltipPosition="bottom"
            ></button>
            <!-- カート追加 -->
            <button
              type="button"
              pButton
              label="選択"
              icon="pi pi-plus-circle"
              (click)="addCart()"
              class="p-mr-2 addCartButton"
            ></button>
          </div>
          <div class="p-as-center p-d-flex">
            <!-- 全件csv出力-->
            <button
              type="button"
              pButton
              icon="pi pi-file-o"
              (click)="exportCSV()"
              class="p-mr-2"
              pTooltip="全件CSV"
              tooltipPosition="bottom"
            ></button>
            <div class="switchBtn">
              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="dv.layout == 'list'">
        <div class="dv-header-table-header">
          <!-- dataViewヘッダー -->
          <p-table styleClass="p-datatable-sm generate-input">
            <!-- 一覧画面ヘッダー表示-->
            <ng-template pTemplate="header">
              <tr class="p-col-2">
                <!-- チェックボックスヘッダーの表示 -->
                <th class="inputCheckbox">
                  <p-checkbox name="checkAll" [value]="allChecked" (click)="setCheckAll(dv, $event)"></p-checkbox>
                </th>
                <!-- APIヘッダーの表示 -->
                <ng-container *ngFor="let column of columnOrder">
                  <!-- カラム判定 -->
                  <!-- 画像以外の場合 -->
                  <th
                    [pSortableColumn]="column.field"
                    (click)="onSortChange(column.field)"
                    *ngIf="column.input_type != 'IMAGE'"
                  >
                    {{ column.header }}
                    <p-sortIcon [field]="column.field"></p-sortIcon>
                  </th>
                  <!-- 画像の場合 -->
                  <th *ngIf="column.input_type == 'IMAGE'">
                    {{ column.header }}
                  </th>
                </ng-container>
                <!-- NEWマーク表示ヘッダー -->
                <!-- カテゴリ1が2:新規の場合 -->
                <!-- TODO 森永でNEWマークを表示用に使用 -->
                <th class="newHeader" *ngIf="2 == category1"></th>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-container>
    </ng-template>
    <!-- 一覧画面ボディ表示-->
    <ng-template let-searchResults let-rowIndex="rowIndex" pTemplate="listItem">
      <!-- 一覧画面表示領域-->
      <p-table
        #table
        [value]="[searchResults]"
        styleClass="p-table-striped order-table"
        selectionMode="single"
        dataKey="item_code"
        expandableRows="true"
        (onRowClick)="table.toggleRow($event.data)"
      >
        <!-- 一覧画面ボディ表示-->
        <ng-template pTemplate="body">
          <tr>
            <!-- チェックボックス -->
            <td class="inputCheckbox">
              <p-checkbox name="cartCheckBox" [value]="searchResults" [(ngModel)]="cartSelected"></p-checkbox>
            </td>
            <!-- 一覧表示-->
            <ng-container *ngFor="let column of columnOrder">
              <!-- 一覧項目値表示(詳細画面遷移項目) -->
              <td
                class="transitionDetail"
                (click)="detail(searchResults[getPkeyColumn()], searchResults['kbn'])"
                *ngIf="'P' == column.column_pkey; else notPkey"
              >
                <u>{{ searchResults[column.field] }}</u>
              </td>
              <!-- 一覧項目値表示 -->
              <ng-template #notPkey>
                <!-- カラム判定 -->
                <td [ngSwitch]="column?.field">
                  <span *ngSwitchCase="'item_tanka'">
                    {{ searchResults[column.field] | number }}
                  </span>
                  <span *ngSwitchCase="'stock_num'">
                    <span *ngIf="!searchResults['ondemand']">
                      <!-- オンデマンドが存在しない場合 -->
                      {{ searchResults[column.field] }}
                    </span>
                  </span>
                  <span *ngSwitchDefault>
                    <!-- TODO 特定カラムID判定は暫定対応-->
                    <span *ngIf="column?.input_type == 'IMAGE'; else PkExcepting">
                      <!-- 画像の場合 -->
                      <img [src]="searchResults[column.field]" alt="image" class="min-img" />
                    </span>
                    <!-- 特定カラムID以外の場合 -->
                    <ng-template #PkExcepting>
                      {{ searchResults[column.field] }}
                    </ng-template>
                  </span>
                </td>
              </ng-template>
            </ng-container>
            <!-- NEWマーク表示ブロック -->
            <!-- カテゴリ1が2:新規の場合 -->
            <!-- TODO 森永でNEWマークを表示用に使用 -->
            <td *ngIf="2 == category1">
              <!-- NEWマーク -->
              <div
                class="newMark"
                *ngIf="outputNewMark(searchResults.update_datetime)"
              >
                <div class="newCharacter">NEW</div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
    <ng-template let-searchResults pTemplate="gridItem">
      <div class="p-col-12 p-md-6">
        <div class="item-area item">
          <div class="top">
            <div class="top-name">
              <div class="title">
                <ng-container *ngFor="let column of columnOrder">
                  <p *ngIf="column.field == 'item_code'">{{ column.header }}：{{ searchResults[column.field] }}</p>
                </ng-container>
              </div>
              <div class="full-name">
                <ng-container *ngFor="let column of columnOrder">
                  <p *ngIf="column.field == 'item_name'">{{ column.header }}：{{ searchResults[column.field] }}</p>
                </ng-container>
              </div>
            </div>
            <div class="top-img">
              <ng-container *ngFor="let column of columnOrder">
                <div *ngIf="column?.input_type == 'IMAGE'; else PkExcepting">
                  <!-- 画像の場合 -->
                  <img [src]="searchResults[column.field]" alt="image"/>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="p-d-flex">
            <div class="p-as-center agenda-kbn">
              <p-tag
                value="課題"
                styleClass="task-tag"
              ></p-tag>
            </div>
            <div class="p-as-center attr">
              <ng-container *ngFor="let column of columnOrder">
                <div [ngSwitch]="column?.field">
                  <div class="p-as-center attr" *ngSwitchCase="'ondemand'">{{ column.header }}：{{ searchResults[column.field] }}</div>
                  <div class="p-as-center attr" *ngSwitchCase="'stock_num'">{{ column.header }}：{{ searchResults[column.field] }}</div>
                  <div class="p-as-center attr" *ngSwitchCase="'item_tanka'">{{ column.header }}：{{ searchResults[column.field] }}</div>
                </div>
              </ng-container>
            </div>
            <div class="p-as-center update-datetime">
              <!-- {{ resultTaskData.update_datetime }} 更新 -->
              <!-- チェックボックス -->
              <p-checkbox name="cartCheckBox" [value]="searchResults" [(ngModel)]="cartSelected"></p-checkbox>
              追加
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(dv, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- スクロールカート追加ボタン -->
<button
  id="addCartButton"
  type="button"
  pButton
  label="選択"
  icon="pi pi-plus-circle"
  (click)="addCart()"
  class="p-mr-2 addCartButton scrollAddCartButton"
></button>

<!-- ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  class="dialog"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 詳細画面 -->
<app-set-main-detail></app-set-main-detail>