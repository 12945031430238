/**
 * 入力項目生成定数
 */
// 入力画面フラグ
export const GENERATE_INPUT_TYPE = {
  // 新規
  NEW: 'new',
  // 編集
  EDIT: 'edit',
  // 複製
  DUPLICATE: 'duplicate',
};

// 入力画面タイトル
export const GENERATE_INPUT_TITLE = {
  // 新規
  NEW: '新規',
  // 編集
  EDIT: '編集',
  // 複製
  DUPLICATE: '複製',
};
