export const STOCK_MAINTENANCE_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'stockMaintenance',
  // 在庫メンテナンスID
  STOCK_MAINTENANCE_ID: 'stock_maintenance_id',
  // ステータス
  STATUS: 'status',
  // 対象テーブル
  TARGET_TABLE: 'item_stock_maintenance',
};
