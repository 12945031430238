<!-- csvファイル登録ボタン表示領域 -->
<div class="csvUploadButtonArea">
  <!-- csvファイル登録ボタン -->
  <app-csv-upload-button
    [buttonName]="buttonName"
    [tableId]="tableId"
    [execType]="execType"
    [toleranceExtension]="toleranceExtension"
    [addData]="addData"
    (csvUpload)="outputCsvUploadData($event)"
    (csvErrorMessage)="outputCsvErrorMessage($event)"
    (captureFlag)="outputCaptureFlag($event)"
  ></app-csv-upload-button>
</div>

<!-- CSVファイル登録エラーメッセージ -->
<app-csv-error-message></app-csv-error-message>

<!-- CSVファイル確認画面 -->
<app-csv-upload-data
  [csvUploadHeaderTemplateId]="csvUploadHeaderTemplateId"
  (outputCsvUploadData)="insertData($event)"
  (captureFlag)="outputCaptureFlag($event)"
></app-csv-upload-data>
