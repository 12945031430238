import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_SEARCH_FILE_UPLOAD_HISTORY,
  API_URL_UPLOAD_DETAIL,
} from 'manager/http-constants_key';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import {
  FILE_UPLOAD_HISTORY_TEMPLATE,
  ITEM_SEARCH_TEMPLATE,
} from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ORDER$CATEGORY1 } from 'src/app/shared/constant/db-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.scss'],
})

/**
 * ファイルアップロード履歴詳細画面
 */
export class HistoryDetailComponent implements OnInit {
  
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_FILE_UPLOAD_HISTORY;
  // public searchEndPoint: string = API_URL_SEARCH_ORDER_ITEM;

  // 検索項目生成テンプレートID
  searchTemplateId: number = FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_TEMPLATE_ID;
  // searchTemplateId: number = ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();
  columnJobLog: any[] = new Array();

  // ジョブID(パスパラメータ) ※初期値はパスパラメータ
  job_id: string = this.route.snapshot.params.job_id;

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // カート選択リスト格納先
  cartSelected: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  // サブレコードヘッダー情報格納先
  columnSubRecord: any[] = new Array();

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();
    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_RESULTS_DETAIL_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
    });
    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    /* ファイル一括登録状況詳細取得処理(画面用) */
    this.dbOperationService
      .getDisplayData(
        API_URL_UPLOAD_DETAIL,
        FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_RESULTS_DETAIL_TEMPLATE_ID,
        this.job_id,
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // ファイル一括登録状況詳細情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3);

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }
  /**
   * 画面遷移
   */
  toReturn(): void{
    this.router.navigate([
      'pages/secretariat/fileUploadHistory',
    ]);
  }
}
