<p-toolbar>
  <div class="p-toolbar-group-left">
    <!-- メニュー-->
    <!--メッセージモード以外の場合、表示-->
    <button
      #btn
      pButton
      type="button"
      icon="pi pi-bars"
      class="p-button-text p-button-secondary button"
      (click)="menu.toggle($event)"
      *ngIf="headerFlag"
    ></button>
    <p-tieredMenu #menu [model]="menuitems" [popup]="true"></p-tieredMenu>

    <!-- TOP画面-->
    <!--メッセージモード以外の場合、表示-->
    <button
      pButton
      type="button"
      icon="pi pi-home"
      (click)="onHome()"
      class="p-button-text p-button-secondary button"
      *ngIf="headerFlag"
    ></button>

    <!-- ボタン無し時の空div -->
    <div class="noneButton" *ngIf="!headerFlag"></div>
  </div>

  <!-- システム名 -->
  <div class="p-toolbar-group-center">
    <label>{{ title }}</label>
  </div>

  <div class="p-toolbar-group-right">
    <!-- カート画面 -->
    <!--ログインユーザの組織種別が 2:寄贈先 or 9:その他 の場合、表示-->
    <!--メッセージモード以外の場合、表示-->
    <button
      pButton
      type="button"
      icon="pi pi-shopping-cart"
      (click)="onCart()"
      class="p-button-text p-button-secondary button"
      *ngIf="headerFlag && cartDisplayFlag"
    ></button>

    <!-- ボタン無し時の空div -->
    <div class="noneButton" *ngIf="!(headerFlag && cartDisplayFlag)"></div>
  </div>
</p-toolbar>
