import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  API_URL_EVENT,
  API_URL_INPUT_QUANTITY_PLANS,
  API_URL_QUANTITY_PLANS,
  API_URL_QUANTITY_PLAN_EVENTS,
  API_URL_SEARCH_QUANTITY_PLANS,
} from 'manager/http-constants_key';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { PROJECT_NUMBER_INPUT_TEMPLATE } from 'manager/template-constant';
import { ErrorMessage, InputErrorMessage } from './project-number-input';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CONSTANT } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import {
  API_RESPONSE,
  DICVALUES_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { forkJoin, Observable } from 'rxjs';
import { DEPARTMENT$DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { ADD_QUANTITY_PLAN_API_CONSTANT } from './constant';

@Component({
  selector: 'app-project-number-input',
  templateUrl: './project-number-input.component.html',
  styleUrls: [
    '../project.component.scss',
    './project-number-input.component.scss',
  ],
})

/**
 * 計画数入力画面
 */
export class ProjectNumberInputComponent implements OnInit {
  // 計画No(クエリパラメータ) ※初期値はクエリパラメータ
  no: FormControl = this.formBuilder.control(
    this.route.snapshot.queryParams.no,
    Object()
  );

  // 保持された計画No(プルダウンキャンセル時に使用)
  backNo: string;

  // 画面.入力フォーム
  inputForm: FormGroup = this.formBuilder.group(Object());
  // 画面.一括入力フォーム
  bulkInputForm: FormGroup = this.formBuilder.group(Object());

  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_QUANTITY_PLANS;

  // 検索項目生成テンプレートID
  public searchTemplateId: number =
    PROJECT_NUMBER_INPUT_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 計画変更プルダウン
  projectChangeInput: Object = new Object();

  // イベントヘッダー情報格納先
  eventColumnOrder: any[] = new Array();

  // イベント検索結果一覧格納先
  eventSearchResults: any[] = new Array();

  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面入力項目ヘッダー情報格納先
  inputColumnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面.入力項目生成
  inputItemList: any[] = new Array();

  // 辞書値格納先
  dicValuesList: any[] = new Array();

  // 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)
  inputErrorMessageList: InputErrorMessage[] = new Array();

  // ページ数
  pages: number = 0;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private loadingState: LoadingState,
    private messageData: MessageData,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('projectChangeInput');

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('event');

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('projectNumber');

    /* 計画変更プルダウン取得処理 */
    {
      // 計画変更プルダウン取得
      this.dbOperationService
        .getData(
          API_URL_QUANTITY_PLAN_EVENTS,
          // ログインユーザを判定
          DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER ==
            this.loginUser.department_type
            ? // ログインユーザが管理ユーザの場合
              PROJECT_NUMBER_INPUT_TEMPLATE.MANAGEMENT_PROJECT_CHANGE_INPUT_TEMPLATE_ID
            : // ログインユーザが一般ユーザの場合
              PROJECT_NUMBER_INPUT_TEMPLATE.GENERAL_PROJECT_CHANGE_INPUT_TEMPLATE_ID
        )
        .subscribe((response) => {
          // 取得データの1行目の入力種類がシングルセレクトか否か
          if (
            (INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE =
              response.body[0].input_type)
          ) {
            // 入力種類がシングルセレクトの場合

            // 計画変更プルダウンを格納
            this.projectChangeInput = response.body[0];
          } else {
            // 入力種類がシングルセレクト以外の場合

            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
                position: TOAST.BOTTOM_RIGHT,
                life: 60000,
              })
            );
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, 'projectChangeInput');
        });
    }

    /* イベントヘッダー情報取得処理(画面用) */
    {
      this.dbOperationService
        .getHeaderList(
          PROJECT_NUMBER_INPUT_TEMPLATE.EVENT_SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          // イベントヘッダー情報のJSONをオブジェクトに格納する
          this.eventColumnOrder = response.body;

          // イベントヘッダー情報分ループ
          for (const headerInformation of response.body) {
            // イベントヘッダー情報に使用辞書番号が存在するか否か
            if (!headerInformation.column_dictionary_id) {
              // 使用辞書番号が存在しない場合
              continue;
            }

            /* 辞書情報を取得 */
            {
              // 使用辞書番号で辞書情報を取得する
              this.dbOperationService
                .getDicValues(headerInformation.column_dictionary_id)
                .subscribe((dicvalues) => {
                  // 辞書情報を格納
                  this.dicValuesList = this.dicValuesList.concat(
                    dicvalues.body[0].dic_value
                  );
                });
            }
          }

          // イベント情報取得処理を実施
          this.eventSearchResult();
        });
    }

    /* ヘッダー情報取得処理(画面用) */
    {
      this.dbOperationService
        .getHeaderList(PROJECT_NUMBER_INPUT_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
        .subscribe((response) => {
          // ヘッダー情報を格納
          this.columnOrder = response.body;

          // 検索処理を実施
          this.searchResult(true);
        });
    }

    /* 入力項目ヘッダー情報取得処理 */
    {
      // 入力項目ヘッダー情報取得
      this.dbOperationService
        .getData(
          API_URL_INPUT_QUANTITY_PLANS,
          PROJECT_NUMBER_INPUT_TEMPLATE.INPUT_TEMPLATE_ID
        )
        .subscribe((response) => {
          /* 一括入力項目を生成 */
          // 入力項目項目分ループ
          for (const input of response.body) {
            // フォームコントロールを追加
            this.bulkInputForm.addControl(
              input.column_id,
              this.formBuilder.control(CONSTANT.EMPTY_STRING, Object())
            );
          }

          /* 入力項目ヘッダー情報を格納 */
          this.inputColumnOrder = response.body;
        });
    }
  }

  /**
   * イベント情報取得処理
   */
  private eventSearchResult() {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      }),
      'event'
    );

    /* イベント情報取得処理(画面用) */
    {
      // イベント情報取得
      this.dbOperationService
        .getSingleData(
          API_URL_EVENT,
          PROJECT_NUMBER_INPUT_TEMPLATE.EVENT_SEARCH_RESULTS_TEMPLATE_ID,
          this.commonService.getPkeyColumn(this.eventColumnOrder),
          this.no.value
        )
        .subscribe((response) => {
          // イベント情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // イベント情報の先頭行のJSONをオブジェクトに格納する
            this.eventSearchResults = response.body.slice(0, 1);
          } else {
            this.eventSearchResults = new Array();
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, 'event');
        });
    }
  }

  /**
   * 検索処理
   * @param initialFlag true:初期表示,プルダウン切り替え時 false:通常検索時
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(
    initialFlag: boolean,
    table?: any,
    generateSearchItems?: Object
  ) {
    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      }),
      'projectNumber'
    );

    // 検索条件に無条件で計画Noを追加
    {
      // 初期表示か否か
      if (!generateSearchItems) {
        // 初期表示の場合

        // 検索条件オブジェクトを生成
        generateSearchItems = new Object();
      }

      // 検索条件に計画Noを追加
      generateSearchItems['event_no'] = this.no.value;
    }

    /* 計画数一覧取得処理(画面用) */
    {
      // 非同期同時実行リスト
      const task: Observable<any>[] = [
        /* 計画数一覧取得処理(画面用) */
        this.dbOperationService.getData(
          API_URL_QUANTITY_PLANS,
          PROJECT_NUMBER_INPUT_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
          generateSearchItems
        ),
        /* 計画数入力項目取得処理 */
        this.dbOperationService.getData(
          API_URL_INPUT_QUANTITY_PLANS,
          PROJECT_NUMBER_INPUT_TEMPLATE.INPUT_TEMPLATE_ID
        ),
      ];

      // 非同期同時実行
      forkJoin(task).subscribe((responseList) => {
        // 計画数一覧情報、計画数入力項目が存在するか否か
        if (this.commonService.checkNoneResponseList(responseList)) {
          // 計画数一覧情報or計画数入力項目が存在しない場合

          // 画面.入力フォームを初期化
          this.inputForm = this.formBuilder.group(Object());

          // 空配列をオブジェクトに格納する
          this.searchResultsList = new Array();

          // 空配列をオブジェクトに格納する
          this.inputItemList = new Array();

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd('projectNumber');

          // 処理を終了
          return;
        }

        // 画面.入力フォーム情報を一時保存
        const saveInputForm: FormGroup = this.inputForm;

        // 画面.入力フォームを初期化
        this.inputForm = this.formBuilder.group(Object());

        /* 入力フォーム用コントロール作成 */
        // 計画数一覧分ループ
        for (const searchResults of responseList[0].body) {
          // 子フォームグループを生成
          let childFormGroup = this.formBuilder.group(Object());

          // 計画数入力項目分ループ
          for (const input of responseList[1].body) {
            // 子フォームグループに入力フォームコントロールを追加
            childFormGroup.addControl(
              input.column_id,
              this.formBuilder.control(CONSTANT.EMPTY_STRING, {})
            );
          }

          // 子フォームグループを入力フォームに格納
          this.inputForm.addControl(
            searchResults[this.getPkeyColumn()],
            childFormGroup
          );
        }

        // 計画数一覧情報のJSONをオブジェクトに格納する
        this.searchResultsList = responseList[0].body;

        // 計画数入力項目のJSONをオブジェクトに格納する
        this.inputItemList = responseList[1].body;

        /* 初期情報をセット */
        if (initialFlag) {
          // 入力項目初期値セットを実施
          this.setFormControlDefaultValue(generateSearchItems);
        } else {
          // 検索前情報セットを実施
          this.setFormControlBeforeSearchValue(saveInputForm);
        }
      });
    }

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 入力項目初期値セット
   */
  private setFormControlDefaultValue(generateSearchItems?: Object) {
    /* 計画数入力初期値取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_QUANTITY_PLANS,
        PROJECT_NUMBER_INPUT_TEMPLATE.INPUT_DEFAULT_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        /* 入力フォーム初期値設定 */
        // 計画数一覧分ループ
        for (const searchResults of this.searchResultsList) {
          // 計画数入力項目分ループ
          for (const inputItem of this.inputItemList) {
            // 子フォームグループの入力フォームに入力値をセット
            this.inputForm.controls[
              searchResults[this.commonService.getPkeyColumn(this.columnOrder)]
            ]
              .get(inputItem.column_id)
              .setValue(
                this.commonService.getArrayObjectValue(
                  response.body,
                  this.commonService.getPkeyColumn(this.columnOrder),
                  inputItem.column_id,
                  searchResults[
                    this.commonService.getPkeyColumn(this.columnOrder)
                  ]
                )
              );
          }
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, 'projectNumber');
      });
  }

  /**
   * 検索前情報セット
   * @param saveInputForm　一時保存した入力フォーム情報
   */
  private setFormControlBeforeSearchValue(saveInputForm?: FormGroup) {
    /* 前回データを再セット */
    // 計画数一覧分ループ
    for (const searchResults of this.searchResultsList) {
      // 計画数入力項目分ループ
      for (const inputItem of this.inputItemList) {
        // 一時保存したフォームコントロールが存在するか否か
        if (saveInputForm.controls[searchResults[this.getPkeyColumn()]]) {
          // フォームコントロールが存在する場合

          // 子フォームグループに一時保存した値を再セット
          this.inputForm.controls[
            searchResults[this.commonService.getPkeyColumn(this.columnOrder)]
          ]
            .get(inputItem.column_id)
            .setValue(
              saveInputForm.controls[searchResults[this.getPkeyColumn()]].get(
                inputItem.column_id
              ).value
            );
        }
      }
    }

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3, 'projectNumber');
  }

  /**
   * 計画変更プルダウン
   * @param table テーブル状態
   */
  protected projectChange(table: any) {
    // 確認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00009),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      // "はい"を押下
      accept: () => {
        // 計画確認画面のクエリ文字列を変更(同URLの為、遷移しない)
        this.router.navigate(['pages/project/project-number-input'], {
          queryParams: { no: this.no.value },
        });

        // イベント情報取得処理を実施
        this.eventSearchResult();

        // 一時保存した検索条件で検索処理を実施
        this.searchResult(true);

        // テーブル状態をリセット
        table.reset();
      },
      // "いいえ"を押下
      reject: () => {
        // プルダウンを変更前の状態に戻す
        this.no.setValue(this.backNo);
      },
    });
  }

  /**
   * 計画一覧画面遷移
   */
  protected returnProjectList() {
    // 計画一覧画面へ遷移
    this.router.navigate(['pages/project/project-list']);
  }

  /**
   * イベント詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected eventDetail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.title = 'イベント情報';
    inputGenerateDisplay.endPoint = API_URL_EVENT;
    inputGenerateDisplay.templateId =
      PROJECT_NUMBER_INPUT_TEMPLATE.EVENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.title = '届先グループ情報';
    inputGenerateDisplay.endPoint = API_URL_QUANTITY_PLANS;
    inputGenerateDisplay.templateId =
      PROJECT_NUMBER_INPUT_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 一括入力ボタン
   */
  protected onClickBulkInput() {
    // 一括入力ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00008),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 一括入力値存在チェックフラグ
        let existenceCheckBulkInput: boolean;

        // 画面入力項目ヘッダー情報分ループ
        for (const inputColumn of this.inputColumnOrder) {
          // 一括入力値を取得
          const bulkInputData = this.bulkInputForm.get(
            inputColumn.column_id
          ).value;

          // 一括入力値が存在するか否か
          if (!bulkInputData) {
            // 一括入力値が存在しない場合

            // 次の画面入力項目ヘッダー情報を処理
            continue;
          }

          // 一括入力値が1件以上存在する為、一括入力値存在チェックフラグをONにする
          existenceCheckBulkInput = true;

          // 画面検索結果一覧分ループ
          for (const searchResults of this.searchResultsList) {
            // 子フォームグループの入力フォームに一括入力値をセット
            this.inputForm.controls[
              searchResults[this.commonService.getPkeyColumn(this.columnOrder)]
            ]
              .get(inputColumn.column_id)
              .setValue(bulkInputData);
          }
        }

        // 一括入力値存在チェックフラグがOFFのままの場合
        if (!existenceCheckBulkInput) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00018),
              detail: this.commonService.msg(MESSAGE_CODE.E00019),
            })
          );
        }
      },
    });
  }

  /**
   * 計画数確認
   */
  protected checkRegistr() {
    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = new Array();

    /* 登録用リクエストオブジェクト作成 */
    // 入力フォームオブジェクトを生成
    let inputRequest: object[] = new Array();
    {
      // 計画数一覧情報分ループ
      for (const searchResults of this.searchResultsList) {
        // 主キーに紐づく入力値のグループを取得
        const inputValue =
          this.inputForm.controls[searchResults[this.getPkeyColumn()]].value;

        /* 入力値のグループのいずれかに入力されているか判定 */
        {
          // 入力状態フラグ(trueのデータのみ、入力チェック及び登録を実施)
          let inputFlag: boolean;

          // 製作指示入力項目分ループ
          for (const inputItem of this.inputItemList) {
            // 入力カラムのデータが存在しているか否か
            if (
              !this.commonService.ifZeroPermission(
                inputValue[inputItem[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID]]
              )
            ) {
              // データが存在していない場合

              continue;
            }

            // 入力チェック及び登録対象のデータと判定
            inputFlag = true;

            break;
          }

          // 入力チェック及び登録対象のデータか否か
          if (!inputFlag) {
            // 入力チェック及び登録対象のデータではない場合

            continue;
          }
        }

        // 入力フォームオブジェクトを生成
        let inputForm: object = new Object();

        // 届先グループコードを格納
        inputForm[this.getPkeyColumn()] = searchResults[this.getPkeyColumn()];

        // 届先グループ名称を格納
        inputForm[ADD_QUANTITY_PLAN_API_CONSTANT.DELIVERY_GROUP_NAME] =
          searchResults[ADD_QUANTITY_PLAN_API_CONSTANT.DELIVERY_GROUP_NAME];

        // 入力フォームオブジェクトに対象の入力フォームを格納
        inputForm = Object.assign(inputForm, inputValue);

        // 入力フォームオブジェクトをリストに格納
        inputRequest.push(inputForm);
      }
    }

    // 入力フォームオブジェクトリストが存在するか否か
    if (!inputRequest.length) {
      // 入力フォームオブジェクトリストが存在しない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00004),
          detail: this.commonService.msg(MESSAGE_CODE.E00020),
        })
      );

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00006),
        changeText: '',
      })
    );

    /* 入力確認 */
    {
      // 入力値を確認
      this.dbOperationService
        .insertData(API_URL_QUANTITY_PLANS, {
          registFlag: 0,
          event_no: this.no.value,
          list: inputRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // バリデーションチェック状態を確認
          if (API_RESPONSE.FAIL == response.body[0].result) {
            // チェック状態が異常の場合

            // エラーメッセージを出力
            this.createErrorMessage(response.body[0]);

            // 処理を終了
            return;
          }

          // 登録を実施
          this.registr(inputRequest);
        });
    }
  }

  /**
   * 登録
   * @param inputRequest 登録用リクエストオブジェクト
   */
  private registr(inputRequest: object[]) {
    // 確認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.I00016),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.I00008),
            changeText: '',
          })
        );

        // 登録を実施
        {
          // 入力値を登録
          this.dbOperationService
            .insertData(API_URL_QUANTITY_PLANS, {
              registFlag: 1,
              event_no: this.no.value,
              list: inputRequest,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (
                this.messageData.resultResponseToastMessage(
                  response,
                  null,
                  this.commonService.msg(MESSAGE_CODE.I00005)
                )
              ) {
                // レスポンスが正常終了の場合

                // イベント情報取得処理を実施
                this.eventSearchResult();

                // 一時保存した検索条件で検索処理を実施
                this.searchResult(true);
              }
            });
        }
      },
    });
  }

  /**
   * エラーメッセージを出力
   * @param errorMessage APIから返却されたエラーメッセージ
   */
  private createErrorMessage(errorMessage: any): void {
    //  画面検索結果一覧分ループ
    for (const searchResults of this.searchResultsList) {
      // 主キーに紐づくエラーメッセージを取得
      const errorMessageData =
        errorMessage.error[searchResults[this.getPkeyColumn()]];

      // エラーメッセージが存在しない場合
      if (!errorMessageData) {
        continue;
      }

      // 入力フォームエラー情報オブジェクト生成
      let inputErrorMessage: InputErrorMessage = new InputErrorMessage();

      // 主キーをセット
      inputErrorMessage.itemId = searchResults[this.getPkeyColumn()];

      // エラーメッセージリスト生成
      let errorMessageList: ErrorMessage[] = new Array();

      // 主キーに紐づくエラーメッセージでループ
      for (const error of errorMessageData) {
        // エラーメッセージオブジェクト生成
        let errorMessage: ErrorMessage = new ErrorMessage();

        // カラムIDをセット
        errorMessage.columnId =
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID];

        // カラム名称をセット
        errorMessage.columnName = this.commonService.getArrayObjectValue(
          this.inputItemList,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_NAME,
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID]
        );

        // エラーメッセージをセット
        errorMessage.message = error.message;

        // エラーメッセージリストに格納
        errorMessageList.push(errorMessage);
      }

      // エラーメッセージリストをセット
      inputErrorMessage.errorMessageList = errorMessageList;

      // 入力フォームエラー情報オブジェクトをリストに格納
      this.inputErrorMessageList.push(inputErrorMessage);
    }
  }

  /**
   * エラー項目チェック
   * @param pkeyId 主キー
   * @param column_id 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(pkeyId: string, columnId: string): boolean {
    // 入力エラーメッセージ出力オブジェクトリスト分ループ
    for (const inputErrorMessage of this.inputErrorMessageList) {
      // 入力エラーメッセージ出力オブジェクトの販促資材IDと引数.販促資材コードが一致するか否か
      if (pkeyId == inputErrorMessage.itemId) {
        // 条件が一致する場合

        // エラーメッセージリストのエラーメッセージ存在チェック
        // 対象項目が存在する場合、trueを返却
        return inputErrorMessage.errorMessageList.some(
          (errorMessage) => errorMessage.columnId === columnId
        );
      }
    }

    return false;
  }

  /**
   * コード値変換
   * @param spName コード名称
   * @returns コード値
   */
  protected changeCode(spName: string): number {
    // コード値を取得
    const code = this.commonService.getArrayObjectValue(
      this.dicValuesList,
      DICVALUES_API_CONSTANT.NAME,
      DICVALUES_API_CONSTANT.CODE,
      spName
    );

    return code;
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
