<div class="multi-select-tree-container">
  <div class="multi-select-tree-trigger-container" (click)="toggleDropdown()"  tabindex="0">
    <div class="multi-select-tree-trigger">
      <ng-container *ngIf="selectedNodes.length > 0; else placeholder">
        <div *ngFor="let node of selectedNodes" class="selected-item">
          {{ node.label }}
        </div>
      </ng-container>
      <ng-template #placeholder>
        <span class="placeholder">{{triggerPlaceholder}}</span>
      </ng-template>

      <span class="clear-value-content" [attr.aria-controls]="'button'" *ngIf="selectedNodes.length > 0">
        <i (click)="onClearSelectNodes()" class="pi pi-times"></i>
      </span>
    </div>
    <span class="dropdown-button" [attr.aria-controls]="'button'">
      <i class="pi pi-fw pi-chevron-down"></i>
    </span>
  </div>

  <div *ngIf="dropdownVisible" [ngClass]="{'multi-select-tree-content': true, 'multi-select-tree-content-up': dropdownPosition == 'up'}">
    <p-tree
      [value]="options"
      selectionMode="checkbox"
      [(selection)]="selectedNodes"
      (selectionChange)="onSelectionChange($event)"
      >
      <ng-template pTemplate="item" let-node>
        <div class="tree-item">
          <span>{{ node.label }}</span>
        </div>
      </ng-template>
    </p-tree>
  </div>
</div>
