/** 発注管理 */
export const BREADCRUMB_ORDER = {
  SELECT_ITEM: '案件選択',
  SELECT_DELIVERY: '委託先選択',
  // TODO 森永では不要
  //SELECTED: '選択リスト',
  QUANTITY_INPUT: '確認',
};

/** 操作モードメッセージ */
export const OPERATION_MODE_SUMMARY_MESSAGE = {
  CATEGORY1_NORMAL: '通常モード',
  CATEGORY1_NEW: '新規モード',
  CATEGORY1_SEND: '送込モード',
};
