export const ORDER_REGISTER_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: '配送依頼情報',
};

/** 注文登録/注文確認 */
export const ADD_ORDER_API_CONSTANT = {
  // 数量
  ORDER_NUM: 'order_num',
  // カラムID
  COLUMN_ID: 'column_id',
  // 販促資材コード
  ITEM_CODE: 'item_code',
  // 販促資材名称
  ITEM_NAME: 'item_name',
  // メッセージ
  MESSAGE: 'message',
  // エラー値
  VALUE: 'value',
};
