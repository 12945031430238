/** 注文オブジェクト */
export class OrderRegisterRequest {
  // 登録フラグ(0:入力フォームの検証,1:入力フォームの登録)
  // 初期値は検証フラグ
  private registFlag: number = 0;

  // 入力フォームオブジェクトリスト
  private list: object[];

  set setRegistFlag(_registFlag: number) {
    this.registFlag = _registFlag;
  }

  get getRegistFlag(): number {
    return this.registFlag;
  }

  set setList(_list: object[]) {
    this.list = _list;
  }

  get getList(): object[] {
    return this.list;
  }
}

/** 入力エラーメッセージオブジェクト */
export class InputErrorMessage {
  // 販促資材名称
  private column_name: string;

  // 販促資材ID
  private column_id: string;

  // エラーメッセージリスト
  private errorMessageList: ErrorMessage[];

  set _columnName(_columnName: string) {
    this.column_name = _columnName;
  }

  get _columnName(): string {
    return this.column_name;
  }

  set _columnId(_columnId: string) {
    this.column_id = _columnId;
  }

  get _columnId(): string {
    return this.column_id;
  }

  set _errorMessageList(_errorMessageList: ErrorMessage[]) {
    this.errorMessageList = _errorMessageList;
  }

  get _errorMessageList(): ErrorMessage[] {
    return this.errorMessageList;
  }
}

/** エラーメッセージオブジェクト */
export class ErrorMessage {
  // カラムID
  private column_id: string;

  // カラム名称
  private column_name: string;

  // エラーメッセージ
  private message: string;

  set _columnId(_columnId: string) {
    this.column_id = _columnId;
  }

  get _columnId(): string {
    return this.column_id;
  }

  set _columnName(_columnName: string) {
    this.column_name = _columnName;
  }

  get _columnName(): string {
    return this.column_name;
  }

  set _message(_message: string) {
    this.message = _message;
  }

  get _message(): string {
    return this.message;
  }
}
