/** ファイル識別子 */
export const FILE_IDENTIFIER = {
  // CSVファイル
  CSV: 'CSV',
  // TSVファイル
  TSV: 'TSV',
  // Excelファイル
  EXCEL: 'EXCEL',
  // PDFファイル
  PDF: 'PDF',
};

/** リクエスト識別子 */
export const REQUEST_IDENTIFIER = {
  // GET
  GET: 'GET',
  // POST
  POST: 'POST',
};
