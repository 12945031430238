/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部はpath,admit,department_level,department_typeを設定
 * 個別権限の場合はindividual_authority_数値連番:{path,admit,department_level,department_type}で設定(値は必ずどれか1つを設定)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * path,admit,department_level,department_typeの場合、設定値は以下の通り設定
 * 1.department_type:''(空)→チェックを行わない
 * 2.department_type:'9'(1つ)→設定値でチェック
 * 3.department_type:'1,9'(2つ以上)→該当設定値に一致するかチェック
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 個別権限(individual_authority_数値連番)の場合、設定値は以下の通り設定
 * 1.department_type:''(空)→チェックを行わない
 * 2.department_type:'9'(1つ)→設定値でチェック
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 注:
 * 画面パス(path)には、前方に'/'をつけない
 * 全体権限、個別権限では個別権限が優先される
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
/**
 * TOP
 */
// TOP画面
export const TopComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BizdayComponent = {
  // 画面パス
  path: 'pages/master/bizday',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 届先マスタ画面
export const DeliveryComponent = {
  // 画面パス
  path: 'pages/master/delivery',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,4,9',
};

// 組織マスタ画面
export const DepartmentComponent = {
  // 画面パス
  path: 'pages/master/department',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,4,9',
};

// お知らせマスタ画面
export const InformationComponent = {
  // 画面パス
  path: 'pages/master/information',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 資材マスタ画面
export const ItemComponent = {
  // 画面パス
  path: 'pages/master/item',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,4,9',
};

// ユーザーマスタ画面
export const UserComponent = {
  // 画面パス
  path: 'pages/master/user',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,4,9',
};

// セット品マスタ画面
export const SetMainComponent = {
  // 画面パス
  path: 'pages/master/setMain',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// ファイル一括登録(マスタ)
export const FileUploadMasterComponent = {
  // 画面パス
  path: 'pages/master/fileUploadMaster',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,9',
};

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面
export const ProductionInstructionComponent = {
  // 画面パス
  path: 'pages/stock/productionInstruction',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,9',
};

// 在庫メンテナンスファイル登録画面
export const RegisterStockMaintenanceComponent = {
  // 画面パス
  path: 'pages/stock/registerStockMaintenance',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3,4,9',
};

// 在庫メンテナンス管理画面
export const StockMaintenanceComponent = {
  // 画面パス
  path: 'pages/stock/stockMaintenanc',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,3,4,9',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/stock/stockMaintenanc',
    // ユーザマスタ.権限レベル
    admit: '1',
    // 組織マスタ.組織レベル
    department_level: '',
    // 組織マスタ.組織種別
    department_type: '2',
  },
};

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const CartComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 発注届先画面
export const DeliverySearchComponent = {
  // 画面パス
  path: 'pages/order/delivery-search/1,pages/order/delivery-search/2,pages/order/delivery-search/3',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 発注販促資材画面
export const ItemSearchComponent = {
  // 画面パス
  path: 'pages/order/item-search/1,pages/order/item-search/2',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 発注販促資材リスト画面
export const ItemSearchListComponent = {
  // 画面パス
  path: 'pages/order/item-search-list/3',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 発注実績画面
export const OrderHistoryComponent = {
  // 画面パス
  path: 'pages/order/orderHistory',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 注文登録画面
export const OrderRegisterComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// 発注画面
export const QuantityComponent = {
  // 画面パス
  path: 'pages/order/quantity/1,pages/order/quantity/2,pages/order/quantity/3',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,3,9',
};

// ファイル一括登録(配送依頼)
export const FileUploadOrderComponent = {
  // 画面パス
  path: 'pages/order/fileUploadOrder',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,9',
};

/** --------------------------------------------------------- */

/**
 * 計画　利用しない
 */
// 計画一覧画面
export const ProjectListComponent = {
  // 画面パス
  path: 'pages/project/project-list',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,9',
};

// 計画数確認画面
export const ProjectNumberConfirmationComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,9',
};

// 計画数入力画面
export const ProjectNumberInputComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,9',
};

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const OutputComponent = {
  // 画面パス
  path: 'pages/secretariat/output',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3,4,9',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/secretariat/output',
    // ユーザマスタ.権限レベル
    admit: '1',
    // 組織マスタ.組織レベル
    department_level: '',
    // 組織マスタ.組織種別
    department_type: '9',
  },
};

// 発送ステータスファイル登録画面
export const RegisterShippingResultComponent = {
  // 画面パス
  path: 'pages/secretariat/registerShippingResult',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3,4,9',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/secretariat/registerShippingResult',
    // ユーザマスタ.権限レベル
    admit: '1',
    // 組織マスタ.組織レベル
    department_level: '',
    // 組織マスタ.組織種別
    department_type: '9',
  },
};

// ファイル一括登録状況(JOB)
export const FileUploadHistoryComponent = {
  // 画面パス
  path: 'pages/secretariat/fileUploadHistory',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,9',
};

// ファイル一括登録状況詳細(JOB)
export const HistoryDetailComponent = {
  // 画面パス
  path: '',
  // path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,2,9',
};


/** --------------------------------------------------------- */

/**
 * 帳票
 */
// 費用明細帳票出力画面
export const CostDetailsReportComponent = {
  // 画面パス
  path: 'pages/report/costDetailsReport',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '9',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/report/costDetailsReport',
    // ユーザマスタ.権限レベル
    admit: '1',
    // 組織マスタ.組織レベル
    department_level: '',
    // 組織マスタ.組織種別
    department_type: '1',
  },
};

// 棚卸帳票出力画面
export const InventoryReportComponent = {
  // 画面パス
  path: 'pages/report/inventoryReport',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '9',
};

/** --------------------------------------------------------- */
