<div class="content">
  <!-- ヘッダー -->
  <header>
    <app-header [headerFlag]="headerFlag"></app-header>
  </header>
  <!-- メイン画面 -->
  <main>
    <router-outlet></router-outlet>
  </main>
  <!-- フッダー -->
  <footer>
    <app-footer></app-footer>
  </footer>
</div>

<!-- メッセージ -->
<app-message-common></app-message-common>

<!-- ローディング画面 -->
<app-loading></app-loading>
