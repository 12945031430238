import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_ORDER_DELIVERIE,
  API_URL_SEARCH_ORDER_DELIVERIE,
  API_URL_SUB_WORK_ORDERS,
  API_URL_WORK_ORDER,
  API_URL_WORK_ORDER_DELIVERIE,
} from 'manager/http-constants_key';
import { DataView } from 'primeng/dataview';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { DELIVERIES_SEARCH_TEMPLATE } from 'manager/template-constant';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { DELIVERISE_SEARCH_CONSTANT } from './constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  DEPARTMENT$DEPARTMENT_TYPE,
  USER$ADMIT,
} from 'src/app/shared/constant/db-constant';

@Component({
  selector: 'app-delivery-search',
  templateUrl: './delivery-search.component.html',
  styleUrls: ['../order.component.scss', './delivery-search.component.scss'],
})

/**
 * 発注届先画面
 */
export class DeliverySearchComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // カテゴリ1(クエリパラメータ)
  // TODO 森永で同一ユーザで3画面切り分けるために使用
  category1: string;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER_DELIVERIE;

  // 検索項目生成テンプレートID
  public searchTemplateId: number =
    DELIVERIES_SEARCH_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // カート選択リスト格納先
  cartSelected: any[] = new Array();

  // 全選択チェックボックス
  allChecked: boolean = false;

  // 検索条件保持
  generateSearchItems: Object;

  // dataView ソートカラム名
  sortField: string;

  // dataView ソート順 (1:昇順, -1:降順)
  sortOrder: number;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private messageData: MessageData,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // パスパラメータの変更を検知
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    this.route.paramMap.subscribe((paramsMap) => {
      // パスパラメータを格納
      this.category1 = paramsMap.get('category1');

      // ワークテーブル削除処理
      this.dbOperationService
        .deleteData(API_URL_SUB_WORK_ORDERS, 'work_order_delivery')
        .subscribe(() => {
          console.log(
            this.commonService.msg(MESSAGE_CODE.D00005, '届先選択情報')
          );
        });

      // 画面ロードフラグをON(ロード中状態)
      this.loadingState.loadStart();

      /* ヘッダー情報取得処理(画面用) */
      this.dbOperationService
        .getHeaderList(DELIVERIES_SEARCH_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
        .subscribe((response) => {
          this.columnOrder = response.body;
        });

      // 検索処理を実施
      this.searchResult();
    });
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // カート選択リストを初期化
    this.cartSelected = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 届先マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        DELIVERISE_SEARCH_CONSTANT.TARGET_TABLE,
        API_URL_ORDER_DELIVERIE,
        DELIVERIES_SEARCH_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 届先マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER_DELIVERIE;
    inputGenerateDisplay.templateId =
      // ログインユーザの判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
      USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // 一般ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.GENERAL_OUTPUT_TEMPLATE_ID
        : // ログインユーザの判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
            this.loginUser.department_type &&
          USER$ADMIT.ADMIT_MANAGEMENT == this.loginUser.admit
        ? // 承認ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.APPROVAL_OUTPUT_TEMPLATE_ID
        : // 管理ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected.length) {
      // カートが選択されていない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00015),
          detail: this.commonService.msg(MESSAGE_CODE.E00016, '届先'),
        })
      );

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00012),
      })
    );

    // カート選択リスト格納先から届先コードのみを取得する
    const code = this.commonService.createArrayGetArrayObject(
      this.cartSelected,
      this.commonService.getPkeyColumn(this.columnOrder)
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER_DELIVERIE, {
        delivery_id: code.join(CONSTANT.COMMA),
        category1: this.category1,
      })
      .subscribe((response) => {
        // レスポンスを判定
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.I00014),
            this.commonService.msg(MESSAGE_CODE.I00019)
          )
        ) {
          // レスポンスが正常終了の場合

          // TODO 森永案件のみ処理を追加
          // 注文情報(WORK)登録
          this.dbOperationService
            .insertData(API_URL_WORK_ORDER, {
              category1: this.category1,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (this.commonService.checkRunningNormallyResponse(response)) {
                // レスポンスが正常終了の場合

                // 発注管理_数量へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/quantity/' + this.category1,
                ]);
              } else {
                // 発注管理_販促資材選択へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/item-search/' + this.category1,
                ]);
              }
            });
        }
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      DELIVERISE_SEARCH_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      DELIVERISE_SEARCH_CONSTANT.TARGET_TABLE,
      API_URL_ORDER_DELIVERIE,
      // ログインユーザの判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // 一般ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
        : // ログインユーザの判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
            this.loginUser.department_type &&
          USER$ADMIT.ADMIT_MANAGEMENT == this.loginUser.admit
        ? // 承認ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.APPROVAL_CSV_TEMPLATE_ID
        : // 管理ユーザの場合
          DELIVERIES_SEARCH_TEMPLATE.MANAGEMENT_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }

  /**
   * 画面スクロール実行時に実行
   */
  @HostListener('window:scroll', ['$event'])
  protected onScroll(event) {
    // スクロール位置の高さが200以上の場合
    if (window.scrollY > 200) {
      // "スクロールカート追加ボタン"を表示
      document.getElementById('addCartButton').classList.add('show');
    } else {
      // "スクロールカート追加ボタン"を非表示
      document.getElementById('addCartButton').classList.remove('show');
    }
  }

  /**
   * チェックボックス全選択
   */
  protected setCheckAll(dv: DataView, event: PointerEvent) {
    event.preventDefault()
    if(this.allChecked){
      this.cartSelected = new Array();
      this.allChecked = false;
    }else{
      this.cartSelected = this.searchResultsList.slice(dv.first, dv.first + dv.rows);
      this.allChecked = true;
    }
  }
  
  /**
   * dataView ソート処理
   */
   protected onSortChange(column: string) {
    let setOrder;
    if(this.sortField == column){
      setOrder = this.sortOrder == 1 ? -1 : 1;
    }else{
      setOrder = 1
    }
    this.sortOrder = setOrder;
    this.sortField = column;
  }
}
