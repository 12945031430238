<!-- CSVデータ表示領域-->
<div class="csv-upload-data">
  <!-- CSVデータ出力領域 -->
  <p-table
    [value]="csvUploadData.list"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    [(first)]="pages"
    styleClass="p-datatable-striped csv-upload-data-table"
    *ngIf="csvUploadData"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-center">
        <!-- 登録ボタン -->
        <button
          type="button"
          pButton
          label="登録"
          (click)="insert()"
          class="p-mr-2"
        ></button>

        <!-- 戻るボタン -->
        <button
          type="button"
          pButton
          label="戻る"
          (click)="clear()"
          class="p-mr-2"
        ></button>
      </div>
    </ng-template>

    <!-- csv情報ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- データNo -->
        <th>No</th>

        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th>
            {{ column.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- csv情報ボディ表示-->
    <ng-template pTemplate="body" let-csvData let-index="rowIndex">
      <tr>
        <td>
          <!-- データNoを表示 -->
          {{ index + 1 }}
        </td>
        <!-- csv情報一覧表示-->
        <td *ngFor="let column of columnOrder">
          <!-- csv情報一覧項目値表示 -->
          <!-- 主キーの場合 -->
          <span *ngIf="column?.column_pkey == 'P'; else notPkey">
            {{ csvData[column.field] }}
          </span>
          <!-- 主キー以外の場合 -->
          <ng-template #notPkey>
            <!-- 型が数値の場合 -->
            <span *ngIf="column?.input_type == 'NUMBER'">
              {{ csvData[column.field] | number }}
            </span>
            <!-- 型が文字の場合 -->
            <span *ngIf="column?.input_type == 'VARCHAR'">
              <!-- コード変換をしない -->
              <a *ngIf="column?.column_dictionary_id == ''; else codeValue">
                {{ csvData[column.field] }}
              </a>
              <!-- コード変換をする -->
              <ng-template #codeValue>
                {{
                  changeCodeValue(
                    column.column_dictionary_id,
                    csvData[column.field]
                  )
                }}
              </ng-template>
            </span>
            <!-- 型が日付の場合 -->
            <span *ngIf="column?.input_type == 'DATE'">
              {{ csvData[column.field] }}
            </span>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- 登録確認ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
