<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped master-datatable"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 戻る-->
          <button
            type="button"
            (click)="toReturn()"
            class="p-mr-2"
            label="戻る"
            tooltipPosition="bottom"
            pButton
          ></button>
        </div>
        <div>
        </div>
      </div>
    </ng-template>
    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th [pSortableColumn]="column.field">
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示 -->
          <td [ngSwitch]="column?.input_type">
            <span *ngSwitchCase="'NUMBER'">
              {{ searchResults[column.field] | number }}
            </span>
            <span *ngSwitchDefault>
              {{ searchResults[column.field] }}
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</mat-drawer-container>
