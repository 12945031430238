<!-- 入庫登録画面 -->
<p-dialog [header]="title" [(visible)]="inputModal" [modal]="true" [draggable]="false" >
  <div><b>セット外装を必ず1点入れてください（-SET-で検索）</b></div>
  <!-- 検索フォーム -->
  <div class="generateInputSetMaterialSearch">
    <form [formGroup]="generateInputSetMaterialSearch">
      <!-- 検索情報出力領域 -->
      <p-table
        [value]="generateSearchList"
        styleClass="p-datatable-sm generate-input"
      >
        <!-- 入力情報タイトル -->
        <ng-template pTemplate="header">
          <tr>
            <!-- 入力項目 -->
            <td class="input-form">
              <!-- 入力項目タイプを判定 -->
              <!-- テキスト -->
              <div class="ui-fluid">
                <input
                  type="VALUE"
                  pInputText
                  formControlName="VALUE"
                />
              </div>
            </td>
            <!-- ラジオボタン -->
            <td class="input-form">
              <div *ngFor="let condition of conditions" class="p-radioButton">
                  <p-radioButton
                  [inputId]="condition.key"
                  [value]="condition.key"
                  [(ngModel)]="searchRadio"
                  [checked]="condition.key == searchRadio"
                  formControlName="TYPE">
                  </p-radioButton>
                    <label [for]="condition.key" >{{condition.name}}</label>
              </div>
            </td>
            <!-- ボタンエリア -->
            <td>
              <input
                pButton
                type="submit"
                value="検索"
                class="p-button-raised p-mr-2 button"
                (click)="onSubmit()"
              />
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>
    <div *ngIf="!searchFlg">
      <!-- 検索情報出力領域 -->
      <p-table
        #table
        [value]="[{}]"
        styleClass="p-datatable-striped master-datatable"
        selectionMode="single"
        [(selection)]="materialSelected"
      >
        <!-- 入力情報タイトル -->
        <ng-template pTemplate="header">
          <!-- APIヘッダーの表示 -->
          <th>
            <p>選択</p>
          </th>
          <ng-container *ngFor="let column of columnOrder">
            <!-- <th [pSortableColumn]="column.field"> -->
            <th *ngIf="column.field != 'col_var1'" [pSortableColumn]="column.field">
                {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </th>
          </ng-container>
        </ng-template>
      </p-table>
      <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    </div>
  </div>

  <!-- 選択フォーム -->
  <div class="generateInputSetMaterialForm" *ngIf="searchFlg">
    <form >
      <!-- 検索情報出力領域 -->
      <p-table
        #table
        [value]="searchResultsList"
        styleClass="p-datatable-striped master-datatable"
        selectionMode="single"
        [(selection)]="materialSelected"
      >
        <!-- 入力情報タイトル -->
        <ng-template pTemplate="header">
          <!-- APIヘッダーの表示 -->
          <th>
            <p>選択</p>
          </th>
          <ng-container *ngFor="let column of columnOrder">
            <!-- <th [pSortableColumn]="column.field"> -->
            <th *ngIf="column.field != 'col_var1'" [pSortableColumn]="column.field">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </th>
          </ng-container>
        </ng-template>

      <!-- 一覧画面ボディ表示-->
      <ng-template pTemplate="body" let-searchResults>
        <tr>
          <!-- チェックボックス -->
          <td class="inputCheckbox">
            <p-tableCheckbox [value]="searchResults"></p-tableCheckbox>
          </td>
          <!-- 一覧表示-->
          <ng-container *ngFor="let column of columnOrder">
            <!-- 一覧項目値表示(詳細画面遷移項目) -->
            <!-- <td> -->
            <!-- <td *ngIf="column.column_input_update != 4"> -->
            <td *ngIf="column.field != 'col_var1'">
              <span>
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      </p-table>
    </form>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <input
        pButton
        type="submit"
        value="選択"
        class="p-button-raised p-mr-2 button"
        (click)="returnGenerateInputSetMaterial()"
      />
    </div>
  </div>
</p-dialog>