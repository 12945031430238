<!-- Toastメッセージ表示(最前面配置) -->
<p-toast
  [key]="toastMessageConstants.TOP_RIGHT + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.TOP_RIGHT"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.TOP_LEFT + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.TOP_LEFT"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_RIGHT + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.BOTTOM_RIGHT"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_LEFT + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.BOTTOM_LEFT"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.TOP_CENTER + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.TOP_CENTER"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateY(-100%)"
  hideTransformOptions="translateY(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_CENTER + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.BOTTOM_CENTER"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
  showTransformOptions="translateY(100%)"
  hideTransformOptions="translateY(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.CENTER + toastMessageConstants.FRONT_KEY"
  [position]="toastMessageConstants.CENTER"
  [preventOpenDuplicates]="true"
  [styleClass]="toastMessageConstants.FRONT_KEY"
></p-toast>
<!-- Toastメッセージ表示(通常配置) -->
<p-toast
  [key]="toastMessageConstants.TOP_RIGHT"
  [position]="toastMessageConstants.TOP_RIGHT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.TOP_LEFT"
  [position]="toastMessageConstants.TOP_LEFT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_RIGHT"
  [position]="toastMessageConstants.BOTTOM_RIGHT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_LEFT"
  [position]="toastMessageConstants.BOTTOM_LEFT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.TOP_CENTER"
  [position]="toastMessageConstants.TOP_CENTER"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateY(-100%)"
  hideTransformOptions="translateY(-100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.BOTTOM_CENTER"
  [position]="toastMessageConstants.BOTTOM_CENTER"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateY(100%)"
  hideTransformOptions="translateY(100%)"
></p-toast>
<p-toast
  [key]="toastMessageConstants.CENTER"
  [position]="toastMessageConstants.CENTER"
  [preventOpenDuplicates]="true"
></p-toast>
