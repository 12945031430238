import { Component, OnInit } from '@angular/core';
import { TOAST } from '../../constant/primeng-constants';

@Component({
  selector: 'app-message-common',
  templateUrl: './message-common.component.html',
  styleUrls: ['./message-common.component.scss'],
})

/**
 * メッセージ
 */
export class MessageCommonComponent implements OnInit {
  // TOASTメッセージ定数情報
  toastMessageConstants = TOAST;

  constructor() {}

  ngOnInit(): void {}
}
