<!-- 発注管理リスト表示領域 -->
<div class="breadcrumb">
  <!-- 発注管理リスト -->
  <app-breadcrumb></app-breadcrumb>
</div>

<!-- 一覧画面-->
<mat-drawer-container class="main" autosize>
  <!-- 一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped order-datatable"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
          <!-- 新規登録表示-->
          <button
            type="button"
            pButton
            label="新規"
            (click)="new()"
            *ngIf="loginUser.department_type == 9"
            class="p-mr-2 newButton"
          ></button>
        </div>
        <div>
          <!-- 計画数合計表示 -->
          <label class="searchSumPlanNumMark">
            <label class="searchSumPlanNumCharacter">
              検索結果計画数合計：{{ searchSumPlanNum }}
            </label>
          </label>
          <!-- 全件csv出力 -->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <!-- カラム判定 -->
          <!-- 画像以外の場合 -->
          <th
            [pSortableColumn]="column.field"
            *ngIf="column.input_type != 'IMAGE'"
          >
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
          <!-- 画像の場合 -->
          <th *ngIf="column.input_type == 'IMAGE'">
            {{ column.header }}
          </th>
        </ng-container>
        <!-- 操作ヘッダーの表示 -->
        <th class="operation">
          <p>操作</p>
        </th>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[getPkeyColumn()], searchResults)"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'IMAGE'">
                <!-- 画像の場合 -->
                <img [src]="searchResults[column.field]" alt="image" />
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>
        <!-- 操作表示 -->
        <td>
          <!-- 計画数入力 -->
          <button
            pButton
            label="計画数入力"
            (click)="planInput(searchResults.item_code)"
            class="p-button-info p-mr-2 projectButton"
            *ngIf="outputDispValid(searchResults)"
          ></button>
          <!-- 計画数確認 -->
          <button
            pButton
            label="計画数確認"
            (click)="planConfirmation(searchResults[getPkeyColumn()])"
            class="p-button-info p-mr-2 projectButton"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      [searchOueryParameters]="searchOueryParameters"
      (generateSearchInformation)="searchResult(table, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input
  [inputOueryParameters]="inputOueryParameters"
  (reloadID)="searchReplacement(table, $event)"
></app-generate-input>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 詳細画面(管理・承認ユーザ)-->
<app-item-search-list-management-detail
  (reloadID)="searchReplacement(table, $event)"
  (reloadDeleteID)="searchDeleteReplacement($event)"
></app-item-search-list-management-detail>
