import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/service/common.service';
import { EXEC_TYPE, DEFAULT_FILE_NAME } from '../constant';
import { CSV_UPLOAD_DATA } from '../csv-upload';
import {
  CONSTANT,
  FILE_EXTENSION,
  LOADING_KEY,
} from 'src/app/shared/constant/constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';
import { LoadData, LoadingState } from '../../../loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from '../../../message-common/message-data';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-csv-upload-button',
  templateUrl: './csv-upload-button.component.html',
  styleUrls: ['./csv-upload-button.component.scss'],
})

/**
 * csvファイル登録ボタン
 */
export class CsvUploadButtonComponent implements OnInit {
  // インプット.csvファイル登録ボタン名称
  @Input() buttonName: string = 'csvファイルアップロード';

  // インプット.対象テーブル(登録や更新を行うテーブル)
  @Input() tableId: string;

  // インプット.csvファイル登録実行タイプ
  // 指定なしの場合、登録を実行
  @Input() execType: string = EXEC_TYPE.INSERT;

  // インプット.許容ファイル拡張子(.csv or .tsv の指定したファイル拡張子のみ設定可能)
  // 指定なし 又は　設定可能以外の文字列の場合、.csv と .tsv　の両拡張子を許容
  @Input() toleranceExtension: string;

  // インプット.バックアップ用ファイル名
  // 指定なしの場合、デフォルトファイル名をセット
  @Input() fileName: string = DEFAULT_FILE_NAME;

  // インプット.登録追加データ
  // ※登録用データに対する情報を追加
  @Input() addData: Object;

  // アウトプット.CSVファイル登録情報オブジェクト
  @Output() csvUpload: EventEmitter<CSV_UPLOAD_DATA> = new EventEmitter();

  // アウトプット.CSVエラーメッセージオブジェクト
  @Output()
  csvErrorMessage: EventEmitter<Object[]> = new EventEmitter();

  // アウトプット.取り込み状態フラグ(true:データ取り込み中,false:取り込みデータなし)
  @Output() captureFlag: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {}

  /**
   * ファイル登録
   * @param event csvファイル情報
   * @param fileUpload ボタン動作状態
   */
  public csvUploadFile(csvFile, fileUpload) {
    // 対象テーブルが存在するか否か
    if (!this.tableId) {
      // 対象テーブルが存在しない場合

      return;
    }

    // ファイル拡張子を取得
    const csvExtension = csvFile.files[0].name.substring(
      csvFile.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    // 許容ファイル拡張子の判定
    if (FILE_EXTENSION.CSV == this.toleranceExtension) {
      // 許容ファイル拡張子が".csv"の場合

      // ファイル拡張子が".csv"か否か判定
      if (FILE_EXTENSION.CSV != csvExtension) {
        // ファイル拡張子が".csv"以外の場合

        // ファイル拡張子エラーメッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00012),
            detail: this.commonService.msg(MESSAGE_CODE.E00013, 'csv'),
          })
        );

        // ボタン動作状態をクリア
        fileUpload.clear();
        return;
      }
    } else if (FILE_EXTENSION.TSV == this.toleranceExtension) {
      // 許容ファイル拡張子が".tsv"の場合

      // ファイル拡張子が".tsv"か否か判定
      if (FILE_EXTENSION.TSV != csvExtension) {
        // ファイル拡張子が".tsv"以外の場合

        // ファイル拡張子エラーメッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00012),
            detail: this.commonService.msg(MESSAGE_CODE.E00013, 'tsv'),
          })
        );

        // ボタン動作状態をクリア
        fileUpload.clear();
        return;
      }
    } else {
      // 許容ファイル拡張子が一致しない場合

      // ファイル拡張子が".csv"又は".tsv"か否か判定
      if (
        !(
          FILE_EXTENSION.CSV == csvExtension ||
          FILE_EXTENSION.TSV == csvExtension
        )
      ) {
        // ファイル拡張子が".csv"又は".tsv"以外の場合

        // ファイル拡張子エラーメッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00012),
            detail: this.commonService.msg(MESSAGE_CODE.E00013, 'csv又はtsv'),
          })
        );

        // ボタン動作状態をクリア
        fileUpload.clear();
        return;
      }
    }

    /* ファイル登録 */
    this.dbOperationService.insertFile(this.fileName, csvFile.files[0]);

    /* ファイル情報取得処理 */
    this.commonService.getFile(csvFile.files[0]).then((csvInformation) => {
      // ファイル情報が返却後、処理を続行

      // ファイル情報チェック
      const rtnMsgCd = this.commonService.checkCsvDataObject(
        csvInformation,
        csvExtension
      );
      if (rtnMsgCd != MESSAGE_CODE.T00000){
        // エラーメッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00012),
            detail: this.commonService.msg(rtnMsgCd),
          })
        );

        // ボタン動作状態をクリア
        fileUpload.clear();
        return;
      }
      // ファイル情報からcsv情報を作成
      const csvDataList = this.commonService.createCsvDataObject(
        csvInformation,
        csvExtension
      );

      // CSVファイル登録情報オブジェクトを生成
      let csvUploadData: CSV_UPLOAD_DATA = new CSV_UPLOAD_DATA();

      // テーブルIDを設定
      csvUploadData.setTableId = this.tableId;

      // 実行タイプを設定
      csvUploadData.setExecType = this.execType;

      // csv情報をリストに格納
      csvUploadData.setList = csvDataList;

      // 登録追加データが存在するか否か
      if (this.addData) {
        // 登録追加データが存在する場合

        // 登録追加データをファイル情報に追加する
        csvUploadData = Object.assign(csvUploadData, this.addData);
      }

      // 画面カスタムロードフラグをON(ロード中状態)
      this.loadingState.customLoadStart(
        new LoadData({
          loadingText: this.commonService.msg(MESSAGE_CODE.S00005),
        }),
        LOADING_KEY.FILE_UPLOAD
      );

      /* CSVファイルデータチェック処理 */
      {
        // 入力項目値確認用API
        this.dbOperationService
          .insertFileData(csvUploadData)
          .subscribe((response) => {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.FILE_UPLOAD);

            // バリデーションチェック状態を確認
            if (API_RESPONSE.FAIL == response.body[0].result) {
              // チェック状態が異常の場合

              //  CSVファイルエラー情報を返却
              this.csvErrorMessage.emit(response.body[0].error);

              // 処理を終了
              return;
            }

            // 親コンポーネントへCSVファイルエラー情報を渡す(空リストの返却によりCSVファイルエラー情報をクリア)
            this.csvErrorMessage.emit([]);

            // CSVファイル登録情報を返却
            this.csvUpload.emit(csvUploadData);

            // データ取り込みフラグをON
            this.captureFlag.emit(true);
          });

        // ボタン動作状態をクリア
        fileUpload.clear();
      }
    });
  }
}
