<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- イベント画面表示領域-->
  <p-table
    [value]="eventSearchResults"
    styleClass="p-datatable-striped project-datatable"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <!-- 検索画面表示-->
        <button
          type="button"
          pButton
          icon="pi pi-search"
          (click)="search.toggle()"
          class="p-mr-2"
          pTooltip="検索"
          tooltipPosition="bottom"
        ></button>

        <!-- 計画変更プルダウン-->
        <div>
          {{ projectChangeInput.column_name }}
          <p-dropdown
            name="projectChangeDropdown"
            styleClass="projectChangeDropdown"
            [options]="projectChangeInput.column_code_list_multi"
            (onChange)="projectChange(table)"
            [(ngModel)]="no"
            optionValue="value"
            optionLabel="name"
            [filter]="true"
            emptyFilterMessage="検索結果が存在しません"
            appendTo="body"
          >
          </p-dropdown>
        </div>

        <div class="p-d-flex">
          <!-- 計画一覧遷移ボタン-->
          <button
            type="button"
            pButton
            label="IMD数量計画一覧へ"
            (click)="returnProjectList()"
            class="p-mr-2 returnProjectList"
          ></button>

          <!-- 全件csv出力-->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let eventColumn of eventColumnOrder">
          <th>
            {{ eventColumn.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-eventSearchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let eventColumn of eventColumnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="eventDetail(eventSearchResults[eventColumn.field])"
            *ngIf="'P' == eventColumn.column_pkey; else notPkey"
          >
            <u>{{ eventSearchResults[eventColumn.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="eventColumn?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                <!-- 数値の場合 -->
                {{ eventSearchResults[eventColumn.field] | number }}
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ eventSearchResults[eventColumn.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 計画数一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped project-datatable"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">計画数</ng-template>
    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th [pSortableColumn]="column.field">
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[getPkeyColumn()])"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                <!-- 数値の場合 -->
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(table, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>
