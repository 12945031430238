import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Injector,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { TreeNode } from 'primeng/api';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-generate-tree-select',
  templateUrl: './generate-tree-select.component.html',
  styleUrls: ['./generate-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GenerateTreeSelectComponent,
      multi: true,
    },
  ],
})
export class GenerateTreeSelectComponent
  implements ControlValueAccessor, OnInit
{
  @Input() options: TreeNode[] = [];
  @Input() outputMode: 'only_sub_val' | 'all' = 'only_sub_val';
  @Input() triggerPlaceholder!: string;
  @Output() selectionChanged = new EventEmitter<TreeNode[]>();

  _options: TreeNode[] = [];
  selectedNodes: TreeNode[] = [];
  dropdownVisible: boolean = false;
  dropdownPosition: string = 'down'; // 'up' or 'down'
  searchText: string = '';
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  public ngControl: NgControl | null = null;

  constructor(
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    @Optional() private injector: Injector,
  ) {}

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null);
    this._options = JSON.parse(JSON.stringify(this.options));
  }

  writeValue(value: string[]): void {
    if (value && Array.isArray(value)) {
      this.fillNodeValue(value);
    } else {
      this.onClearSelectNodes();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue(): void {
    this.onChange(this.getAllDataFields(this.selectedNodes));
    this.markAsDirty();
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
    this.setFocusSelectTrigger();
    if (this.dropdownVisible) {
      // Ensure the view has been updated before calculating position
      setTimeout(() => {
        this.updateDropdownPosition();
        this.cd.detectChanges();
      });
    }
  }
  
  onSelectionChange(_event: any): void {
    this.setValue();
  }

  onClearSelectNodes(): void {
    this.selectedNodes = [];
    this.options = JSON.parse(JSON.stringify(this._options));
    this.setValue();
  }


  updateDropdownPosition(): void {
    const container = this.elementRef.nativeElement.querySelector('.multi-select-tree-container');
    const dropdown = this.elementRef.nativeElement.querySelector('.multi-select-tree-content');

    if (container && dropdown) {
      const rect = container.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < dropdown.scrollHeight && spaceAbove > dropdown.scrollHeight) {
        this.dropdownPosition = 'up';
      } else {
        this.dropdownPosition = 'down';
      }
    }
  }

  private markAsDirty(): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.markAsDirty();
    }
  }

  private getAllDataFields(nodes: TreeNode[]): string[] {
    const dataFieldsSet = new Set<string>();

    const collectDataFields = (nodes: TreeNode[]) => {
      for (const node of nodes) {
        if (node.data) {
          dataFieldsSet.add(node.data);
        }
        if (node.children && Array.isArray(node.children)) {
          collectDataFields(node.children);
        }
      }
    };

    collectDataFields(nodes);
    let results = Array.from(dataFieldsSet);

    if (this.outputMode == 'only_sub_val') {
      const parents = nodes
        .filter((node) => !!node?.children?.length)
        .map(({ data }) => data);
      results = results.filter((val) => !parents.includes(val));
    }

    return results;
  }

  private fillNodeValue(searchValues: string[]): void {
    const queue: TreeNode[] = [...this.options];

    while (queue.length > 0) {
      const node = queue.shift()!;

      if (searchValues.includes(node.data as string)) {
        if (
          !this.selectedNodes.some(
            (selectedNode) => selectedNode.data === node.data
          )
        ) {
          this.selectedNodes.push(node);
        }
      }

      if (node.children) {
        queue.push(...node.children);
      }
    }
    this.activateParentNodes(this.options, this.selectedNodes);
  }

  private activateParentNodes(tree: TreeNode[], targetNodes: TreeNode[]): void {
    const targetDataSet = new Set(targetNodes.map((node) => node.data));

    const traverseAndMarkParents = (
      nodes: TreeNode[],
      parent: TreeNode | null = null
    ): void => {
      for (const node of nodes) {
        if (parent && targetDataSet.has(node.data)) {
          let current = parent;
          while (current) {
            current.partialSelected = true;
            current = current.parent || null;
          }
        }

        // Recursively traverse children
        if (node.children) {
          traverseAndMarkParents(node.children, node);
        }
      }
    };

    const checkIsSelectAllNode = (nodes: TreeNode[], targetNodes: TreeNode[]) => {
      const allChildrenSelected = (children) => {
        return children.every(child => targetNodes.some(target => target.data === child.data));
      };
    
      for (const node of nodes) {
        if (node.children) {
          if (allChildrenSelected(node.children)) {
              node.partialSelected = false;
              targetNodes.push(node)
          } else {
            continue;
          }
          // Recursively check children nodes
          checkIsSelectAllNode(node.children, targetNodes);
        }
      }
    };
    // Traverse the entire tree starting from the root nodes
    traverseAndMarkParents(tree);
    checkIsSelectAllNode(tree, targetNodes);
  }

  private setFocusSelectTrigger = () => {
    if (this.dropdownVisible) {
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('.multi-select-tree-trigger-container'), 'focused');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement.querySelector('.multi-select-tree-trigger-container'), 'focused');
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(target)) {
      this.dropdownVisible = false;
      this.setFocusSelectTrigger();
    }
  }
}
