import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PROD_DOMAIN } from 'manager/environment';

const routes: Routes = new Array();

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: PROD_DOMAIN == window.location.host ? false : true,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
