import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { SetMainDetailComponent } from './set-main-detail/set-main-detail.component';
import {
  API_URL_INPUT_SET,
  API_URL_SEARCH_SET,
  API_URL_DISPLAY_SET_MAIN,
  API_URL_SET,
  API_URL_DUP_SET_MAIN
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { SET_MAIN_CONSTANT } from './constant';
import { SET_MAIN_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';

@Component({
  selector: 'app-set-main',
  templateUrl: './set-main.component.html',
  styleUrls: ['../master.component.scss'],
})

/**
 * セット品マスタ画面
 */
export class SetMainComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(SetMainDetailComponent)
  setMainDetailComponent: SetMainDetailComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_SET;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = SET_MAIN_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(SET_MAIN_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* セット品マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        SET_MAIN_CONSTANT.TARGET_TABLE,
        API_URL_SET,
        SET_MAIN_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // セット品マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail(pkeyId: string) {
    // 詳細画面表示
    this.setMainDetailComponent.initial(pkeyId);
  }

  /**
   * 新規登録画面表示
   */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_SET,
      API_URL_SET,
      SET_MAIN_TEMPLATE.NEW_INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_SET,
      API_URL_DISPLAY_SET_MAIN,
      SET_MAIN_TEMPLATE.EDIT_INPUT_TEMPLATE_ID,
      pkeyId,
      null
    );
  }

  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_SET,
      API_URL_DUP_SET_MAIN,
      SET_MAIN_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  public delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialogSetMain(
      API_URL_DISPLAY_SET_MAIN,
      this.commonService.getPkeyColumnName(this.columnOrder),
      pkeyId
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param table テーブル状態
   * @param pkeyId コード値
   */
  public searchReplacement(table: any, pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが新規,複製の場合 */
    // 返却されたコード値が存在するか否か
    if (!pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施(テーブル状態初期化,全検索)
      this.searchResult(table);

      return;
    }

    /* データが編集かつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(table, this.generateSearchItems);

      return;
    }

    /* データが編集かつソート未実施の場合 */
    /* セット品マスタ一覧再取得処理(画面用) */
    this.dbOperationService
      .getSingleData(
        API_URL_SET,
        SET_MAIN_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.getPkeyColumn(),
        pkeyId
      )
      .subscribe((response) => {
        /* 検索結果一覧から該当データを更新 */
        // 検索結果一覧格納先から対象のデータの行数を抽出
        const indexTargetData = this.commonService.getRowNumberArrayObject(
          this.searchResultsList,
          this.getPkeyColumn(),
          pkeyId
        );

        // 検索結果一覧情報のJSONをオブジェクトに置換する
        this.searchResultsList[indexTargetData] = response.body[0];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * データ削除処理
   * @param pkeyId コード値
   */
  public searchDeleteReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // 検索結果一覧から該当データを削除
    this.searchResultsList = this.commonService.deleteArrayObjectValue(
      this.searchResultsList,
      this.getPkeyColumn(),
      pkeyId
    );

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadEnd();
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
    SET_MAIN_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      SET_MAIN_CONSTANT.TARGET_TABLE_CSV,
      API_URL_SET,
      SET_MAIN_TEMPLATE.CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
