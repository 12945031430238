import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import {
  API_URL_ORDER,
  API_URL_INPUT_ORDER,
  API_URL_SEARCH_ORDER,
  API_URL_ORDER_CANCEL,
  API_URL_CHECK_EDIT_ORDER_RESULT,
  API_URL_APPROVAL_ORDER,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { ORDER_PERFORMANCE_CONSTANT } from './constant';
import {
  DEPARTMENT$DEPARTMENT_TYPE,
  ORDER$CATEGORY1,
  ORDER$STATUS,
  USER$ADMIT,
} from 'src/app/shared/constant/db-constant';
import { ORDER_HISTORY_TEMPLATE } from 'manager/template-constant';
import { UpdateStatusComponent } from './update-status/update-status.component';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['../order.component.scss', './order-history.component.scss'],
})

/**
 * 発注実績画面
 */
export class OrderHistoryComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 更新画面ダイアログ */
  @ViewChild(UpdateStatusComponent)
  updateStatusComponent: UpdateStatusComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = ORDER_HISTORY_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面検索結果一覧内部情報
  searchResultsInformation: any[] = new Array();

  // 辞書値格納先
  dicValuesList: any[] = new Array();

  // 承認選択リスト格納先
  updateSelected: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private messageData: MessageData,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    {
      this.dbOperationService
        .getHeaderList(ORDER_HISTORY_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
        .subscribe((response) => {
          this.columnOrder = response.body;
        });
    }

    /* 画面検索結果一覧内部情報 */
    {
      this.dbOperationService
        .getHeaderList(
          ORDER_HISTORY_TEMPLATE.SEARCH_RESULTS_INFORMATION_TEMPLATE_ID
        )
        .subscribe((response) => {
          // 画面検索結果一覧内部情報格納
          this.searchResultsInformation = response.body;

          // 画面検索結果一覧内部情報分ループ
          for (const headerInformation of response.body) {
            // 画面検索結果一覧内部情報に使用辞書番号が存在するか否か
            if (!headerInformation.column_dictionary_id) {
              // 使用辞書番号が存在しない場合
              continue;
            }

            /* 辞書情報を取得 */
            {
              // 使用辞書番号で辞書情報を取得する
              this.dbOperationService
                .getDicValues(headerInformation.column_dictionary_id)
                .subscribe((dicvalues) => {
                  // 辞書情報を格納
                  this.dicValuesList = this.dicValuesList.concat(
                    dicvalues.body[0].dic_value
                  );
                });
            }
          }
        });
    }

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 承認選択リストを初期化
    this.updateSelected = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 発注実績一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
        API_URL_ORDER,
        ORDER_HISTORY_TEMPLATE.SEARCH_RESULTS_INFORMATION_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 発注実績一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.5);
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER;
    inputGenerateDisplay.templateId =
      // ログインユーザの判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
      USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // 一般ユーザの場合
          ORDER_HISTORY_TEMPLATE.GENERAL_OUTPUT_TEMPLATE_ID
        : // 管理・承認ユーザの場合
          ORDER_HISTORY_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 更新画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected update(pkeyId: string, status: number) {
    // 更新画面表示
    this.updateStatusComponent.initial(pkeyId, status);
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param status 発注ステータス
   * @param category1 製品属性
   */
  protected edit(pkeyId: string, status: string, category1: string) {
    // 製品属性が送込か否か
    if (ORDER$CATEGORY1.CATEGORY1_SEND == category1) {
      // 製品属性が送込の場合

      // 編集画面表示
      this.generateInputComponent.initial(
        GENERATE_INPUT_TYPE.EDIT,
        API_URL_INPUT_ORDER,
        API_URL_ORDER,
        // 発注ステータスの判定
        ORDER$STATUS.STATUS_THREE == status
          ? // 発注ステータスが出荷済みの場合

            // 編集画面表示(出荷済み用)
            ORDER_HISTORY_TEMPLATE.SEND_SHIPPED_INPUT_TEMPLATE_ID
          : // 発注ステータスが出荷済み以外の場合
            ORDER_HISTORY_TEMPLATE.SEND_INPUT_TEMPLATE_ID,
        pkeyId,
        API_URL_CHECK_EDIT_ORDER_RESULT
      );

      return;
    }
    // 製品属性が送込以外の場合

    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_ORDER,
      API_URL_ORDER,
      // 発注ステータスの判定
      ORDER$STATUS.STATUS_THREE == status
        ? // 発注ステータスが出荷済みの場合

          // 編集画面表示(出荷済み用)
          ORDER_HISTORY_TEMPLATE.SHIPPED_INPUT_TEMPLATE_ID
        : // 発注ステータスが出荷済み以外の場合
          ORDER_HISTORY_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId,
      API_URL_CHECK_EDIT_ORDER_RESULT
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete(pkeyId: string) {
    // 更新情報
    let updateValue = Object();

    // 発注ステータスにキャンセルをセット
    updateValue[ORDER_PERFORMANCE_CONSTANT.STATUS] = ORDER$STATUS.STATUS_NINE;

    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.cancelDialog(
      API_URL_ORDER_CANCEL,
      this.commonService.getPkeyColumnName(this.columnOrder),
      pkeyId,
      updateValue
    );
  }

  /**
   * 一括承認
   * @param table テーブル状態
   */
  public bulkApproval(table: any) {
    // 承認選択リスト格納先から承認待ち情報を取得
    const selected = this.commonService.getArrayObjectMultipleValue(
      this.updateSelected,
      'status',
      'order_id',
      '承認者待ち'
    );

    // 承認選択が選択されているか否か
    if (!selected.length) {
      // 承認選択が選択されていない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00021),
          detail: this.commonService.msg(MESSAGE_CODE.E00016, '注文'),
        })
      );

      return;
    }

    // 一括承認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00012),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.U00007),
          })
        );

        // 承認
        this.dbOperationService
          .updateMultipleData(API_URL_APPROVAL_ORDER, {
            order_id: selected.join(CONSTANT.COMMA),
          })
          .subscribe((response) => {
            // レスポンスを判定
            if (
              this.messageData.responseToastMessage(
                response,
                this.commonService.msg(MESSAGE_CODE.U00002),
                this.commonService.msg(MESSAGE_CODE.U00010, '注文ID', '承認')
              )
            ) {
              // レスポンスが正常終了の場合

              // 検索処理を実施
              this.searchResult(table, this.generateSearchItems);
            }
          });
      },
    });
  }

  /**
   * 再検索処理＆データ置換
   * @param table テーブル状態
   * @param pkeyId コード値
   */
  public searchReplacement(table: any, pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが新規,複製の場合 */
    // 返却されたコード値が存在するか否か
    if (!pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施(テーブル状態初期化,全検索)
      this.searchResult(table);

      return;
    }

    /* データが編集、キャンセルかつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(table, this.generateSearchItems);

      return;
    }

    /* データが編集、キャンセルかつソート未実施の場合 */
    /* 発注実績一覧取得処理(画面用) */
    this.dbOperationService
      .getSingleData(
        API_URL_ORDER,
        ORDER_HISTORY_TEMPLATE.SEARCH_RESULTS_INFORMATION_TEMPLATE_ID,
        this.getPkeyColumn(),
        pkeyId
      )
      .subscribe((response) => {
        /* 検索結果一覧から該当データを更新 */
        // 検索結果一覧格納先から対象のデータの行数を抽出
        const indexTargetData = this.commonService.getRowNumberArrayObject(
          this.searchResultsList,
          this.getPkeyColumn(),
          pkeyId
        );

        // 検索結果一覧情報のJSONをオブジェクトに置換する
        this.searchResultsList[indexTargetData] = response.body[0];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ORDER_PERFORMANCE_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
      API_URL_ORDER,
      // ログインユーザの判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // 一般ユーザの場合
          ORDER_HISTORY_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
        : // 管理・承認ユーザの場合
          ORDER_HISTORY_TEMPLATE.MANAGEMENT_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * コード値変換
   * @param spName コード名称
   * @returns コード値
   */
  protected changeCode(spName: string): number {
    // コード値を取得
    const code = this.commonService.getArrayObjectValue(
      this.dicValuesList,
      DICVALUES_API_CONSTANT.NAME,
      DICVALUES_API_CONSTANT.CODE,
      spName
    );

    return code;
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.searchResultsInformation);
  }
}
