export const ORDER_REGISTER_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: '配送依頼情報',
};

/** 数量登録/数量確認 */
export const ADD_ORDER_API_CONSTANT = {
  // チェックボックスキー項目
  CHECK_BOX: 'delivery_type',
  // 販促資材コード
  ITEM_CODE: 'item_code',
  // 会社コード
  DELIVERY_ID: 'delivery_id',
  // メッセージ
  MESSAGE: 'message',
};
