import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_FILE_UPLOAD_HISTORY,
  API_URL_SEARCH_FILE_UPLOAD_HISTORY,
} from 'manager/http-constants_key';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import {
  FILE_UPLOAD_HISTORY_TEMPLATE,
} from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ORDER$CATEGORY1 } from 'src/app/shared/constant/db-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { FILE_UPLOAD_HISTORY_CONSTANT } from './constant';
@Component({
  selector: 'app-file-upload-history',
  templateUrl: './file-upload-history.component.html',
  styleUrls: ['./file-upload-history.component.scss'],
})

/**
 * ファイルアップロード履歴画面
 */
export class FileUploadHistoryComponent implements OnInit {
  
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  // カテゴリ1(クエリパラメータ)
  // TODO 森永で同一ユーザで3画面切り分けるために使用
  category1: string = null;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_FILE_UPLOAD_HISTORY;

  // 検索項目生成テンプレートID
  searchTemplateId: number = FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_TEMPLATE_ID;
  
  // 出力csvファイル名
  CSV_FILENAME: string = 'fileUploadHistory';

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();
    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
    });
    // 検索処理を実施
    this.searchResult();

  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* ファイル一括登録状況一覧取得処理(画面用) */
    this.dbOperationService
      .getForkJoinData(
        FILE_UPLOAD_HISTORY_CONSTANT.TARGET_TABLE,
        API_URL_FILE_UPLOAD_HISTORY,
        FILE_UPLOAD_HISTORY_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // ファイル一括アップロード履歴一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
      this.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      FILE_UPLOAD_HISTORY_CONSTANT.TARGET_TABLE,
      API_URL_FILE_UPLOAD_HISTORY,
      FILE_UPLOAD_HISTORY_TEMPLATE.CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
  

  /**
   * 画面遷移
   */
  toHistoryDetail(job_id): void{
    this.router.navigate([
      'pages/secretariat/fileUploadHistory/historyDetail/' + job_id,
    ]);
  }
}
