import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../order.component.scss', './cart.component.scss'],
})

/**
 * 発注選択画面
 */
export class CartComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
